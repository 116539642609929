import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
`
export const AlarmMessageContainer = styled.div`
  min-width: 275px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  .svgFill {
    fill: ${({ theme }) => theme.colors.white};
  }
  z-index: 6666;
  background: ${({ theme }) => theme.colors.messages.critical};
`

export const IconContainer = styled.div`
  cursor: pointer;
`

export const YandexMapContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%;
  align-self: stretch;
  ${AlarmMessageContainer} {
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ theme, isAlarm }) => isAlarm && `
    border: 3px solid ${theme.colors.messages.critical};
  `}

`

export const BarContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ empty }) => empty && `
    min-height: 48px;
  `}
`

export const MapBarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
`

export const NavContainer = styled.div`
  height: 100%;
  background: #F6F9FB';
  ${({ br, theme }) => br && `border-right: 1px solid  ${theme.colors.borders.default};`}
  ${({ hidden }) => hidden && 'display: none;'}
`
