import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center
margin-top: 5px;
gap: 10px;
width: 100%;
height: 100%;
overflow-x: hidden;
`

export const Card = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: #3E3C3E;
  text-align: left;
  display: flex
  flex-direction: column;
  width: calc(100% - 20px);
  // height: 428px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  background: #FAFBFB;
  padding: 10px;
  gap: 10px;
  ${({maxWidth}) => maxWidth &&`
    max-width: 400px;
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: 0.6;


`

export const Status = styled.div`
  ${({status}) => {
    if (status === 'Исполнено') {
      return `
        color: #90C737;
      `
    }
  }}

`

export const Date = styled.div`
  ${({ theme, overdue }) => overdue && `
  color: ${theme.colors.colors.redisign.error};
`}
`

export const TaskNumber = styled.div`
font-size: 1.5rem;


`

export const EssenceOfAppeal = styled.div`
  font-weight: 400;
`

export const ObjectType = styled.div`
  padding-bottom: 10px;
  font-size: 1.5rem;
  border-bottom: 1px solid #DDDDDD;
  opacity: 0.6;
`

export const DateDecision = styled.div`
  display: flex;
  justify-content: space-between;
  ${({plan}) => plan &&`
    color: #0C4BAD;
  `}
`