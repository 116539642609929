import React from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import Loader from '@/components/blocks/Loader'
import { getIsNotRegion } from '@/helpers/maps'
import { getNodePath } from '@/helpers/viewTree/formaters'
import { filterTypes } from '@/constants/passportization'
import YandexMap from '@/components/blocks/YandexMap'
import PassportSidebar from '@/components/regions/sidebars/PassportSidebar'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import {
  GROUP_OBJECT,
  COUNTRY,
  REGION,
  CITY,
  OBJECT_ELEMENT,
  GEOZONE,
} from '@/constants/objectTypes'
import { MAP_SERVICE } from '@/constants/applications'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'
import { withRouter } from 'react-router-dom'
import REQUEST_STATUSES from '@/constants/requests'
import {
  CONNECTED_LINES,
  MESH,
  CONNECTED_LINES_OBJECT_TYPES
} from '@/constants/instalationPassport/types'
import { mash, mashDisplayElements } from '@/data/mapTree/mashTreeData'
import HeaderPageMobile from '@/components/blocks/HeaderPageMobile'
import ButtonTree from '@/components/blocks/ButtonTree'
import { ROOT } from '@/constants/objectTypes'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import {
  Main,
  MapControls,
  MapControlsContent,
} from './styles'
import GlobalPopup from "@/components/blocks/GlobalPopup";

const withHooks = (Component) => {
  return function(props) {
    const isMobile = useGetIsMobile()
    useHtmlTitle(props.intl.messages['menu.passportization'])
    return (
        <Component {...props} isMobile={isMobile}/>
    );
  };
}
class Passportization extends React.PureComponent {
  handleChange = debounce((value) => {
    this.setState({ search: value })
  }, 300)

  constructor(props) {
    super(props)
    const {
      treeData, regions, userApplications, displayElements
    } = props
    this.state = {
      search: '',
      hoverZoneByID: null,
      itemsOnSelectedRegion: [],
      hoveredItemByID: null,
      idOfSelectedZone: null,
      typesOfRegionPins: filterTypes,
      treeStructureData: cloneDeep(treeData),
      dataSet: { regions, pins: displayElements },
      passportIsOpen: false,
      selectedZoneIdentifier: null,
      typesWithoutPassportization: [
        GROUP_OBJECT,
        COUNTRY,
        REGION,
        CITY,
      ],
      userApplications: userApplications,
      savedNode: {},
      isChildren: true,
      openTree: false,
      openMapLayer: false,
    }
  }

  componentDidUpdate() {
    const { selectedNode, requestGetPinsAndZonesAndTelemetry, displayElementAndTelemetryRequestStatus, roots } = this.props
    if (displayElementAndTelemetryRequestStatus === REQUEST_STATUSES.NOT_REQUESTED && roots.length && selectedNode && selectedNode.id && selectedNode.type !== ROOT) {
        requestGetPinsAndZonesAndTelemetry({
          parentTreeId: selectedNode.path[1]|| selectedNode.id,
        })
      this.setState(() => ({
        selectedZoneIdentifier: selectedNode.treeNodeType === GEOZONE ? selectedNode.id : selectedNode.path[1],
      }))
    }
  }

  getIDOfSelectedZone = (element) => () => {
    this.setState(() => ({
      idOfSelectedZone: element.id,
    }))
  }

  setMapCenterForLocationZoom = (element) => () => {
    this.setState(() => ({
    }))
  }

  listItemMapMouseEnter = (element) => {
    this.setState(() => ({ hoveredItemByID: element.id }))
  }

  listItemMapMouseLeave = () => {
    this.setState(() => ({ hoveredItemByID: null }))
  }

  treeZoneMouseEnter = (element) => {
    this.setState(() => ({ hoverZoneByID: element.id }))
  }

  treeZoneMouseLeave = () => {
    this.setState(() => ({ hoverZoneByID: null }))
  }

  getSelectedNode = () => {
    const { treeData, selectedNode } = this.props

    return get(treeData, getNodePath(selectedNode), {})
  }

  handleMapLayer = (value) => {
    this.setState({openMapLayer: value})
  }

  handleOpenPassport = (node, fromTree) => {
    const { setNode, selectedNode, requestGetPinsAndZonesAndTelemetry, mapLayer, isMobile, requestGetConnectedLinesCoordinates, pinnedNode } = this.props
    if (isMobile && !fromTree) {
      this.setState({ passportIsOpen: true })
    }
    if (mapLayer !== MESH) {
      this.getIDOfSelectedZone(node)()
      if (!fromTree) {
        this.setState(() => ({
          isChildren: true
        }))
      }
      if (pinnedNode.id !== node.id) {
        setNode(node)
      }

      if (mapLayer === CONNECTED_LINES) {
        if (CONNECTED_LINES_OBJECT_TYPES.some(type => type === node.type)) {
          requestGetConnectedLinesCoordinates({
            id: node.id,
            parentId: node.parentId
          })
        }
        
      }
      if (selectedNode.id) {
        if ((selectedNode.path[1] !== node.path[1] || node.treeNodeType === GEOZONE) && node.type !== ROOT) {
          requestGetPinsAndZonesAndTelemetry({
            parentTreeId: node.path[1] || node.id
          })
        }
      }
    }
  }

  handleClosePassport = () => {
    this.setState({ passportIsOpen: false })
  }

  setSelectedZoneIdentifier = (id) => {
    this.setState(() => ({
      selectedZoneIdentifier: id,
    }))
  }

  selectNodeByZone = (node) => {
    const { requestGetPinsAndZonesAndTelemetry } = this.props
    requestGetPinsAndZonesAndTelemetry({
      includeAll: true,
      parentTreeId: node.id,
    })
  }

  getIsPassportOpen = () => {
    const { pinnedNode, isMobile, } = this.props
    const { passportIsOpen } = this.state

    if (!isMobile || !!pinnedNode.installationType) {
      return true
    }
    if (pinnedNode.treeNodeType === OBJECT_ELEMENT && passportIsOpen) {
      return true
    }

    return false
  }


  getIsTreeOpen = () => {
    const { isGlobalFilterOpen } = this.props
    const { openTree, openMapLayer } = this.state

    if (isGlobalFilterOpen || openMapLayer) {
      return true
    }

    if ( !openTree ) {
      return true
    }

    return false
  }

  onClickButtonTree = () => {
    this.setState({
      openTree: true,
    })
  }

  onCloseTree = () => {
    this.setState({
      openTree: false,
    })
  }

  viewTreeOnSelect = (node, actionType) => {
    const { mapLayer } = this.props
    if (mapLayer !== MESH) {
      if (actionType === 'click') {
        this.setState(() => ({
          isChildren: !!node.childrenCount
        }))
        this.handleOpenPassport(node, true)
        this.setSelectedZoneIdentifier(node.treeNodeType === GEOZONE ? node.id : node.path[1])
      }
      if (actionType === 'in') {
        return getIsNotRegion(node.type)
            ? this.listItemMapMouseEnter(node)
            : this.treeZoneMouseEnter(node)
      }
      if (actionType === 'out') {
        return getIsNotRegion(node.type)
            ? this.listItemMapMouseLeave(node)
            : this.treeZoneMouseLeave(node)
      }
      return null
    } else {
      if (actionType === 'click') {
        this.setState(() => ({
          fromTree: true
        }))
        this.setSelectedZoneIdentifier(node.treeNodeType === GEOZONE ? node.id : node.path[1])
        this.getIDOfSelectedZone(node)()
      }
    }
  }

  submitFieldsPassport = (value) => {
    const {activeFieldsPassport, requestActiveFieldsPassport} = this.props
    requestActiveFieldsPassport({...activeFieldsPassport, ...value})
  }

  render() {
    const {
      hoveredItemByID,
      hoverZoneByID,
      selectedZoneIdentifier,
      userApplications,
      isChildren
    } = this.state
    const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0]?.settings
    const {
      loading,
      operatedElement,
      selectedNode,
      selectedElementEquipment,
      data,
      requestAddPassportFile,
      requestAddPassportPhoto,
      passportFile,
      passportPhoto,
      requestDeletePassportPhoto,
      requestDeletePassportFile,
      requestExportPassportFile,
      requestGetPassportFile,
      setOperatedElement,
      requestUpdatePassport,
      zones,
      pinnedNode,
      intl,
      userData,
      requestCardStatus,
      displayElements,
      globalFilters,
      requestGetObjectConnectedIdentifiers,
      requestGetConnectedLinesCoordinates,
      objectConnectedIdentifiers,
      mapLayer,
      connectedLinesCoordinates,
      isMobile,
      onSetSelectedNode,
      onSetUnpinNode,
      isRussianBorders,
      activeFieldsPassport,
      globalLoading,
      requestGetCompositionNode,
      compositionNode,
      getCompositionNodeStatus,
    } = this.props

    if (loading) return <Loader center />
    return (
      <Main>
        {globalLoading && (
          <GlobalPopup content={<Loader center />} />
        )}
        {isMobile ?
          <>
            {!this.getIsPassportOpen() && <HeaderPageMobile title={'Паспортизация'} moveToPinnedNode={this.handleOpenPassport} mapLayer={mapLayer} handleMapLayer={this.handleMapLayer}/>}
            <TreeSidebar
              title={<Lang id="passportization.title" />}
              onSelect={this.viewTreeOnSelect}
              hidden={this.getIsTreeOpen()}
              isMashMode={mapLayer === MESH}
              selectedNode={selectedNode}
              isChildren={isChildren}
              mapLayer={mapLayer}
              iconDropdown
              isMobile={isMobile}
              onCloseTree={this.onCloseTree}
              isPassportization
          />
            {!this.getIsPassportOpen() && <ButtonTree onClick={this.onClickButtonTree}/>}
          </>
          :
          <TreeSidebar
            title={<Lang id="passportization.title" />}
            onSelect={this.viewTreeOnSelect}
            hidden={false}
            isMashMode={mapLayer === MESH}
            selectedNode={selectedNode}
            isChildren={isChildren}
            mapLayer={mapLayer}
            iconDropdown
            onCloseTree={this.onCloseTree}
            isPassportization
          />
        }
        <MapControls>
          <MapControlsContent>
            { this.getIsPassportOpen() && (
              <PassportSidebar
                type={operatedElement.treeNodeType}
                data={data}
                userData={userData}
                selectedNode={selectedNode}
                operatedElement={operatedElement}
                selectedElementEquipment={selectedElementEquipment}
                setOperatedElement={setOperatedElement}
                requestAddPassportFile={requestAddPassportFile}
                requestAddPassportPhoto={requestAddPassportPhoto}
                passportFile={passportFile}
                passportPhoto={passportPhoto}
                requestDeletePassportFile={requestDeletePassportFile}
                requestDeletePassportPhoto={requestDeletePassportPhoto}
                requestExportPassportFile={requestExportPassportFile}
                requestUpdatePassport={requestUpdatePassport}
                requestGetPassportFile={requestGetPassportFile}
                intl={intl}
                passportIsOpen={true}
                requestStatus={requestCardStatus}
                requestGetObjectConnectedIdentifiers={requestGetObjectConnectedIdentifiers}
                requestGetConnectedLinesCoordinates={requestGetConnectedLinesCoordinates}
                objectConnectedIdentifiers={objectConnectedIdentifiers}
                mapLayer={mapLayer}
                isMobile={isMobile}
                onClosePassport={this.handleClosePassport}
                onSetSelectedNode={onSetSelectedNode}
                onSetUnpinNode={onSetUnpinNode}
                pinnedNode={pinnedNode}
                activeFieldsPassport={activeFieldsPassport[data?.path ? data.path[2] : {}] || {}}
                submitFieldsPassport={this.submitFieldsPassport}
                requestGetCompositionNode={requestGetCompositionNode}
                compositionNode={compositionNode}
                getCompositionNodeStatus={getCompositionNodeStatus}
              />
            )}
              <YandexMap
                pinnedNode={pinnedNode}
                globalZoneId={selectedZoneIdentifier}
                setSelectedZoneIdentifier={this.setSelectedZoneIdentifier}
                isPassportization
                pins={mapLayer === MESH ? mashDisplayElements : displayElements}
                zones={zones}
                onClick={this.handleClosePassport}
                mash={mapLayer === MESH ? mash : []}
                isMashMode={mapLayer === MESH}
                isLinesMode={mapLayer === CONNECTED_LINES}
                hoveredPinByID={hoveredItemByID}
                hoveredZoneByID={hoverZoneByID}
                handleOpenPassport={this.handleOpenPassport}
                selectNodeByZone={this.selectNodeByZone}
                yandexKey={yandexKey}
                globalFilters={globalFilters}
                connectedLinesCoordinates={connectedLinesCoordinates}
                isMobile={isMobile}
                isRussianBorders={isRussianBorders}
              />
          </MapControlsContent>
        </MapControls>
      </Main>
    )
  }
}

Passportization.propTypes = {
  loading: pt.bool.isRequired,
  requestUpdatePassport: pt.func.isRequired,
  setNode: pt.func,
  regions: pt.arrayOf(pt.object).isRequired,
  pins: pt.arrayOf(pt.object).isRequired,
  treeData: pt.shape({
    id: pt.number,
  }).isRequired,
  selectedNode: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    location: pt.arrayOf(pt.number),
    type: pt.string,
    status: pt.string,
  }).isRequired,
  userApplications: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    code: pt.string,
    defaultType: pt.string,
    name: pt.string,
    placement: pt.string,
    settings: pt.string,
    type: pt.string,
  })),
  intl: pt.objectOf(pt.object),
}
Passportization.defaultProps = {
  setNode: noop,
}

export default React.memo(withRouter(withHooks(Passportization)))
