import styled from 'styled-components'
import LabelWithIcon from '@/components/blocks/LabelWithIcon/styles';

export const InputAndLabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  ${LabelWithIcon} {
    margin-bottom: 5px;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  ${({ twoButton }) => twoButton && `
    justify-content: space-between;
    flex-direction: row;
    button:first-child {
      margin-right: 10px;
    }
  `}
  ${({ withErrorMessage }) => withErrorMessage && `
    padding: 5px 15px 0 15px;
  `}
  button {
    width: 100%;
  }
`
export const EditButtonContainer = styled.div`
  padding: 0 15px;
  ${LabelWithIcon} {
    justify-content: flex-end;
  }
`
export const DoubleInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    margin-bottom: auto;
  }

  input {
    width: 95%;
  }

  & :last-child > input {
    margin-left: 5px
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
  }

  & :first-child > input {
    display: flex;
    justify-content: start;
  }
`

export const MessageTitle = styled.div`
  width: 100%;
  padding: 0 15px;
  white-space: pre-wrap;
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme, type }) => {
    if (type === 'pending') {
      return theme.colors.colors.treeIcons
    }
    if (type === 'info') {
      return theme.colors.colors.treeItextFieldcons
    }
    return theme.colors.colors.notation
  }};
`

export const LoaderContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
