import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  text-align: left;
  ${({ theme }) => theme.themeName === LIGHT_THEME_NAME && `
    color:${theme.colors.colors.redisign.default};
  `}
`

export const HeaderRow = styled.div`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  max-height: 48px;
  padding-right: 15px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  ${({ gradient, theme }) => gradient && `
    background: ${theme.colors.backgrounds.redisign.topDownGradient};
  `}
`

export const HeaderRowWrapper = styled.div`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  max-height: 48px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  ${({ gradient, theme }) => gradient && `
    background: ${theme.colors.backgrounds.redisign.topDownGradient};
  `}
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 5px;

  ${({ withoutPadding }) => withoutPadding && `
    padding: 9px 0;
  `}
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  ${({ disabled }) => disabled && `
    cursor: disabled;
    opacity: 0.5;
  `}
`

export const HeaderIconContainer = styled.div`
  margin-right: -15px;
`

export const TreeContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0 10px 10px;
  ${({ userTree }) => userTree && `
    padding-left: 0px;
    padding: 10px;
  `}
  ${({ rightPadding }) => rightPadding && 'padding-right: 10px'}
  ${({ isFilterMode }) => (isFilterMode
    && `visibility: visible;
    height: 50px;`)
}
`

export const SidebarWrapper = styled.div`
  background: #F6F9FB;
  height: calc(100vh - 144px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ isFilterMode }) => {
    if (isFilterMode) {
      return `${TreeContainer} {
        height: 50px;
        overflow: hidden;
      }`
    }
  }}
  .ht_hyperTreeView .ht_hyperTreeNodeWrapper .ht_child {
    max-height: none;
  }
  
  ${({ searchLoading }) => searchLoading &&`
    justify-content: center;
    align-items: center
  `
  }

  @media (max-width: 910px) {
    height: calc(100vh - 494px);
  }
`

export const PageSidebarStyled = styled.div`
  min-width: ${({ theme }) => theme.sizes.sideBarViewTreeMin}px;
  width: 330px;
  flex: 0 0 auto;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  ${({ small, theme }) => small && `
    min-width: 49px;
    width: 49px;

    ${HeaderRow} {
      flex-direction: column;
      height: 100%;
      max-height: 100%;
      justify-content: flex-start;
      padding-right: 0;
      position: absolute;
    }

    ${HeaderContentWrapper} {
      writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
      padding: 25px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    ${IconWrapper} {
      border: none;
      border-bottom: 1px solid ${theme.colors.borders.default}
      svg {
        transform: rotate(180deg);
      }
    }
  `}

  @media (max-width: 910px) {
    width: 100vw;
    position: absolute;
    top: 320px;
    height: calc(100vh - 396px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
    z-index: 200;
  }
`

export const IconCloseWrapper = styled.div`
  display: flex;
  align-items: center;

`

export const ButtonScrollToRoot = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(16, 97, 223, 1);
  border-radius: 5px;
  padding: 8px;
  margin-right: 3px;
`

export const ErrorButtonWraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TableIcon = styled.div`
  width: auto;
  height: 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: relative;
  margin-right: 5px;
`
