import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 360px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`
export const ButtonContainer = styled.div`
  width: 100%;
  button {
    margin: 0 auto;
    padding-right: 20px;
    font-size: 1rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
`
export const LoaderContainer = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MessageContainer = styled.div`
  padding: 1.5rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.colors.subTitle};
`

export const HeaderContainer = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
`

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.logo};
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.backgrounds.controls.green
    if (type === 'red') return theme.colors.backgrounds.controls.red
    return 'transparent'
  }};
  svg {
    width: 18px;
    height: 18px;
  }
  ${({ deleteButton }) => deleteButton && `
    svg {
      width: 28px;
      height: 28px;
    }
  `}
  cursor: pointer;
  ${({ disabled }) => disabled && `
    pointer-events: none;
    cursor: disabled;
    opacity: 0.5;
  `}
`

export const Title = styled.div`
  flex: 1
  height: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;
  color:  ${({ theme }) => theme.colors.colors.disabled};
  border-right: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  ${({ cancelTitle, theme }) => cancelTitle && `
    text-transform: none;
    color: ${theme.colors.colors.logo};
  `}
`
