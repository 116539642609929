export default {
  collapse: 'Collapse',
  openMenu: 'Open Menu',
  deCollapse: 'Expand',
  open: 'Open',
  close: 'Close',
  find: 'Find',
  search: 'Search',
  filter: 'Filter',
  list: 'List',
  help: 'Help',
  options: 'Options',
  notification: 'Notifications',
  settings: 'Settings',
  profile: 'Profile',
  first: 'First page',
  prev: 'Previous page',
  next: 'Next page',
  last: 'Last page',
  refresh: 'Refresh',
  graph: 'Graph',
  table: 'Table',
  build: 'Build',
  createGroup: 'Create a group',
  createTenant: 'Create tenant',
  grid: 'Grid',
  addProcess: 'Add process',
  remove: 'Remove',
  fieldSettings: 'Field settings',
  passport: 'Passport',
  download: 'Download',
  warning: 'Warning',
  warningOn: 'Enable notification',
  warningOff: 'Disable alert',
  tableRefresh: 'Refresh table',
  graphRefresh: 'Refresh graph',
  graphBuild: 'Build graph',
  tableBuild: 'Build table',
  closeFilter: 'Close filter',
  closeList: 'Close List',
  openFilter: 'Open filter',
  openList: 'Open List',
  createIntegration: 'Create Integration',
  cancelIntegration: 'Cancel creation',
  cancel: 'Cancel',
  edit: 'Edit',
  erase: 'Erase',
  move: 'Move',
  resetSearch: 'Reset search results',
  signOut: 'Sign out',
  online: 'Online',
  offline: 'Offline',
  onTop: 'To top',
  exportGroupObjects: 'Export Group objects',
}