import styled from 'styled-components'

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  width: 0px;
  min-width: 0px;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  color: ${({ theme }) => theme.colors.colors.default};
  transition: ${({ theme }) => theme.animations.slide.connect('width 300ms, min-width 300ms')};
  background-color: #dbe2ed;
  ${({ opened, theme }) => opened && `
    width: ${theme.sizes.sidebarWidthMax}px;
    min-width: ${theme.sizes.sidebarWidthMax}px;
    border-right: 1px solid ${theme.colors.borders.default};
  `}
  ${({ opened, small, theme }) => (opened && small) && `
    width: ${theme.sizes.sidebarWidthMin}px;
    min-width: ${theme.sizes.sidebarWidthMin}px;
  `}
  ${({
    over, position, opened, theme,
  }) => over && `
    width: ${theme.sizes.sidebarWidthMax}px;
    min-width: ${theme.sizes.sidebarWidthMax}px;
    position: fixed;
    top: ${theme.sizes.headerHeight}px;
    ${position}: -${theme.sizes.sidebarWidthMax}px;
    bottom: 0;
    // background: ${theme.colors.backgrounds.drawer};
    border-left: ${theme.colors.borders.default};
    transition: ${theme.animations.slide.connect(`${position} 300ms`)};

    ${opened && `
     ${position}: 0px;
    `}
  `}
`

export default Container
