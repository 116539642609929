import styled from 'styled-components'

export const PinContainer = styled.div`
  position: relative;
  top: -8px;
  left: -8px;
  width: 24px;
  height: 24px;
  z-index: 120;
  `

export const OverLay = styled.div`
  box-shadow: ${({ theme }) => theme.colors.shadows.pin};
  background: #040712;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
  svg {
    height: 18px;
    width: 18px;
  }
`

export const UnKnowContainer = styled.div`
  position: relative;
  top: 2px;
  left: 0px;
  width: 22px;
  height: 22px;
  z-index: 120;

  svg:last-child{
    position: absolute;
    top: -20px;
    left: 0px;
    .svgFill {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  svg:first-child {
    position: absolute;
    top: -25px;
    left: -6px;
    .svgFill {
      height: 18px;
      fill: ${({ theme }) => theme.colors.colors.redisign.default};
    }
  }
`
