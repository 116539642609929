import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const DownArrow = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_112_1644)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5L8 12L14 5L2 5Z" fill="#8392AA"/>
      </g>
      <defs>
      <clipPath id="clip0_112_1644">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fillRule="evenodd" clipRule="evenodd" d="M9.24882 12.7786C9.05752 12.9543 8.76352 12.9543 8.57222 12.7786L2.14532 6.87345C1.83222 6.58575 1.83222 6.12525 2.14532 5.83765L2.71832 5.31115C3.03992 5.01563 3.56822 5.01538 3.89022 5.31066L8.57262 9.60346C8.76382 9.77876 9.05722 9.77876 9.24842 9.60346L13.9308 5.31066C14.2528 5.01538 14.7811 5.01564 15.1027 5.31116L15.6757 5.83766C15.9888 6.12526 15.9888 6.58576 15.6757 6.87346L9.24882 12.7786Z" fill={fill} className="svgFill" />
    // </svg>
  )
}

DownArrow.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DownArrow.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DownArrow)
