import styled from 'styled-components'

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
`

export const SelectAllWrapper = styled.div`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2rem;
  line-height: 3rem;
  border-top: 1px solid transparent;
  span {
    margin-left: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  min-height: 47px;
  ${({ border, theme }) => `border-${border || 'left'}: 1px solid ${theme.colors.borders.default}`};
  ${({ type }) => type === 'arrow' && `
    svg {
      transform: rotate(-90deg);
    }
  `}
`

export const TextAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.2rem;
  line-height: 3rem;
  font-style: normal;
  font-weight: normal;

  svg {
    margin-right: 10px;
  }
`

export const Item = styled.div`
  height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.default};
  cursor: pointer;
`

export const List = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const CheckBoxWrapper = styled.div`
  margin: 0 15px;
`

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.colors.default};
  cursor: pointer;
  svg {
    margin-left: 15px;
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
`
