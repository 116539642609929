import styled from 'styled-components'
import Sidebar from '@/components/regions/sidebars/Sidebar'
import { RadioButtonContainer } from '@/components/controls/RadioButton/styles'
import { CollapsedBlockStyled } from '@/components/blocks/CollapsedBlock/styles'
import { LIGHT_THEME_NAME } from '@/constants/names'

export const StyledSidebar = styled(Sidebar)`
  z-index: 10;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        background: #dbe2ed;
        border-left: 1px solid ${theme.colors.borders.redisign.default};
      `
    }
    return null
  }}
`

export const SettingsWrapper = styled.div`
  height: 100%;
  margin-bottom: auto;
  overflow-y: scroll;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        ${CollapsedBlockStyled}:not(:nth-child(2)) {
          margin-top: 10px;
        }
      `
    }
    return null
  }}
`

export const ControlsBlock = styled.div`
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return 'padding: 5px 15px;'
    }
    return 'padding: 10px 15px;'
  }}


  ${RadioButtonContainer} {
    ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return 'margin-bottom: 10px;'
    }
    return 'margin-bottom: 20px;'
  }}

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ control, theme }) => control && `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 15px;
    border-bottom: 1px solid ${theme.colors.borders.redisign.default};
  
  `}
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 43px;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 1.4rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.scheme.backgrounds.default};
  margin-top: 5px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return 'margin-top: 5px;'
    }
    return `background: ${theme.colors.borders.default};`
  }}
`

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 2rem;
  padding: 13px 15px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.09rem;
        height: 39px;
        color: ${theme.colors.colors.default};
      `
    }
    return `
      font-weight: ${theme.fontWeights.bold};
      font-size: 1.4rem;
      text-transform: uppercase;
      color: ${theme.colors.colors.disabled};
    `
  }}
`
