import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
import noop from 'lodash/noop'
import setSixDigitsAfterDot from '@/helpers/setSixDigitsAfterDot'
import {
  GEOZONE,
  PROJECT,
  INTEGRATION,
  OBJECT_ELEMENT,
  DOWNLOAD_INSTALLATION_FILE, ROOT,
} from '@/constants/objectTypes'
import {
  ACTION_TYPE,
  CREATE_TYPE,
  CREATE,
  PUT,
} from '@/constants/semanticNames'

export const typeToZoom = {
  [GEOZONE]: 9,
  [OBJECT_ELEMENT]: 12,
}

export const generateAlerts = (
  type,
  actionType,
  setAlertConfig,
  card,
  closeAlert,
  closeAlertHandler,
  deleteRootGeoZoneHandler,
  deleteGeoZoneHandler,
  deleteProjectHandler,
  deleteObjectHandler,
  deleteIntegrationHandler,
  cancelCreation,
  requestVerifyElements,
  requestEditObject,
  intl
) => {
  const typeToName = {
    [ROOT]: intl.messages['installation.popup.geozone1'],
    [GEOZONE]: intl.messages['installation.popup.geozone1'],
    [PROJECT]: intl.messages['installation.popup.project'],
    [OBJECT_ELEMENT]: intl.messages['installation.popup.object'],
    [INTEGRATION]: intl.messages['installation.popup.integration1'],
  }
  const typeToMessage = {
    [ROOT]: intl.messages['installation.popup.deleteGeoZoneMessage'],
    [GEOZONE]: intl.messages['installation.popup.deleteGeoZoneMessage'],
    [PROJECT]: intl.messages['installation.popup.deleteProjectMessage'],
    [OBJECT_ELEMENT]: '',
    [INTEGRATION]: intl.messages['installation.popup.deleteIntegrationMessage'],
  }

  const typeToAction = {
    [ROOT]: deleteRootGeoZoneHandler,
    [GEOZONE]: deleteGeoZoneHandler,
    [PROJECT]: deleteProjectHandler,
    [OBJECT_ELEMENT]: deleteObjectHandler,
    [INTEGRATION]: deleteIntegrationHandler,
  }
  const actionTypeToAction = {
    [CREATE]: intl.messages['installation.popup.add1'],
    [PUT]: intl.messages['installation.popup.edit1'],
  }
  const actionTypeToActionForCancel = {
    [`${CREATE}-${GEOZONE}`]: intl.messages['installation.popup.installation'],
    [`${PUT}-${GEOZONE}`]: intl.messages['installation.popup.edit'],
    [`${PUT}-${ROOT}`]: intl.messages['installation.popup.edit'],
    [`${CREATE}-${PROJECT}`]: intl.messages['installation.popup.add'],
    [`${PUT}-${PROJECT}`]: intl.messages['installation.popup.edit'],
    [`${CREATE}-${INTEGRATION}`]: intl.messages['installation.popup.add'],
    [`${PUT}-${INTEGRATION}`]: intl.messages['installation.popup.edit'],
    [`${CREATE}-${OBJECT_ELEMENT}`]: intl.messages['installation.popup.installation'],
    [`${PUT}-${OBJECT_ELEMENT}`]: intl.messages['installation.popup.edit'],
  }
  const actionTypeToName = {
    [ROOT]: intl.messages['installation.popup.geozone'],
    [GEOZONE]: intl.messages['installation.popup.geozone'],
    [PROJECT]: intl.messages['installation.popup.project1'],
    [OBJECT_ELEMENT]: intl.messages['installation.popup.object1'],
    [INTEGRATION]: intl.messages['installation.popup.integration'],
  }
  const parentTypeToName = {
    [GEOZONE]: intl.messages['installation.popup.geozone1'],
    [PROJECT]: intl.messages['installation.popup.geozone1'],
    [INTEGRATION]: intl.messages['installation.popup.project'],
    [OBJECT_ELEMENT]: intl.messages['installation.popup.integration1'],
    [DOWNLOAD_INSTALLATION_FILE]: intl.messages['installation.popup.objectOrIntegration'],
  }
  return {
    deleteElementHandler: () => {
      setAlertConfig({
        type: 'critical',
        title: <Lang id={"installation.popup.attention"}/>,
        message: `${intl.messages['installation.popup.deleteMessage']} ${typeToName[type]}\n“${get(card, 'data.name', null)}”\n${intl.messages['installation.popup.fromSystem']}? \n${typeToMessage[type]}`,
        onClose: closeAlert,
        secondaryAction: closeAlert,
        primaryAction: typeToAction[type] || noop,
      })
    },
    createEditElementInvalidValuesHandler: () => {
      setAlertConfig({
        type: 'warning',
        title: <Lang id={"installation.popup.impossible"}/>,
        message: `${intl.messages['installation.popup.for']} ${actionTypeToAction[actionType]} ${actionTypeToName[type]} ${intl.messages['installation.popup.requiredMessage']}`,
        onClose: closeAlert,
        primaryAction: closeAlert,
      })
    },
    selectRootElement: () => {
      setAlertConfig({
        type: 'warning',
        title: <Lang id={"installation.popup.impossible"}/>,
        message: <Lang id={"installation.popup.geoZoneContinueMessage"}/>,
        onClose: closeAlertHandler,
        primaryAction: closeAlertHandler,
      })
    },
    syncElement: () => {
      setAlertConfig({
        type: 'success',
        title: <Lang id={"installation.popup.syncObject"}/>,
        message: <Lang id={"installation.popup.syncObjectMessage"}/>,
        onClose: closeAlertHandler,
        primaryAction: noop,
      })
    },
    parentCreateError: (parentType) => {
      setAlertConfig({
        type: 'warning',
        title: <Lang id={"installation.popup.impossible"}/>,
        message: `${intl.messages['installation.popup.continueMessage']} ${parentTypeToName[parentType]}.`,
        onClose: closeAlertHandler,
        primaryAction: closeAlert,
      })
    },
    cancelCreationEdit: () => {
      setAlertConfig({
        type: 'critical',
        title: <Lang id={"installation.popup.attention"}/>,
        message: `${intl.messages['installation.popup.cancelMessage']} ${actionTypeToActionForCancel[`${actionType}-${type}`]} ${actionTypeToName[type]}?`,
        onClose: closeAlert,
        primaryAction: cancelCreation,
        secondaryAction: closeAlert,
      })
    },
    verifyElement: (data) => {
      setAlertConfig({
        type: 'success',
        title: <Lang id={"installation.popup.syncObject"}/>,
        message: <Lang id={"installation.popup.syncObjectMessage"}/>,
        onClose: closeAlert,
        primaryAction: () => requestVerifyElements(data),
        secondaryAction: () => requestEditObject(data),
      })
    },
    editElement: (data) => {
      setAlertConfig({
        type: 'editSuccess',
        title: <Lang id={"installation.popup.editObject"}/>,
        message: <Lang id={"installation.popup.editObjectMessage"}/>,
        onClose: closeAlert,
        primaryAction: () => requestEditObject(data),
        secondaryAction: closeAlert,
      })
    },
  }
}

export const formatDataForObject = (data = {}, intl) => {
  const { objectElementFieldsDto = [] } = data
  const formattedFields = objectElementFieldsDto.reduce((accumulator, element) => {
    const splitName = element.fieldName.split('.')
    if (element.passportBlock === splitName[0]) {
      return {
        ...accumulator,
        [element.passportBlock]: {
          ...accumulator[element.passportBlock] || {},
          [splitName[1]]: (element.value === 'true' || element.value === 'false') ? JSON.parse(element.value) : element.value,
        },
      }
    }
    return {
      ...accumulator,
      [element.passportBlock]: {
        ...accumulator[element.passportBlock] || {},
        customFields: [
          ...((accumulator[element.passportBlock] || {}).customFields || []),
          {
            ...element,
          },
        ],
      },
    }
  }, {})
  set(formattedFields, 'GENERAL_INFORMATION.NAME', data.name)
  set(formattedFields, 'LOCATION.LATITUDE', data.point?.latitude)
  set(formattedFields, 'LOCATION.LONGITUDE', data.point?.longitude)
  if (data.options) {
    set(formattedFields, 'options', data.options.map((el) => {return {value: el.id, title: el.name }}))
  }
  // if (intl) {
  //   set(formattedFields, 'GENERAL_INFORMATION.EQUIPMENT_TYPE', intl.messages[`globalNames.objectTypes.${data.installationType}`])
  //   set(formattedFields, 'OBJECT_CHARACTERISTIC.CONTROL_TYPE', intl.messages[`globalNames.objectTypes.${data.installationType}`])
  // }
  const prevObjectElementFieldsDto = objectElementFieldsDto.reduce((accumulator, element) => ({
    ...accumulator,
    [element.fieldName]: element,
  }), {})

  return {
    ...formattedFields,
    integrationId: get(data, 'integrationId', null),
    parentTreeElementId: get(data, 'parentId', null),
    integrationType: get(data, 'integrationType', null),
    objectElementId: get(data, 'id', null),
    objectType: get(data, 'installationType', null),
    objectState: get(data, 'objectState', null),
    path: get(data, 'path', null),
    prevObjectElementFieldsDto,
  }
}

export const shouldHaveCoorsToType = {
  [GEOZONE]: true,
  [PROJECT]: false,
  [OBJECT_ELEMENT]: true,
}

export const isRenderGeoZoneForm = (formValues) => (formValues[ACTION_TYPE] === CREATE && formValues[CREATE_TYPE] === GEOZONE)
|| (formValues[ACTION_TYPE] === PUT && (formValues.type === GEOZONE || formValues.type === ROOT))

export const isRenderProjectForm = (formValues) => (formValues[ACTION_TYPE] === CREATE && formValues[CREATE_TYPE] === PROJECT)
|| (formValues[ACTION_TYPE] === PUT && formValues.type === PROJECT)

export const isRenderIntegrationForm = (formValues) => (formValues[ACTION_TYPE] === CREATE && formValues[CREATE_TYPE] === INTEGRATION)
|| (formValues[ACTION_TYPE] === PUT && formValues.type === INTEGRATION)

export const allowNewPointCreation = (
  formValues,
  alertConfig,
) => (shouldHaveCoorsToType[formValues[CREATE_TYPE]]
  ? isEmpty(alertConfig) && formValues[ACTION_TYPE] === CREATE
  : false)

export const newPointWasCreated = (
  formValues,
  alertConfig,
  newPoints,
) => (
  shouldHaveCoorsToType[formValues[CREATE_TYPE]]
    ? isEmpty(alertConfig) && newPoints.length !== 0 && formValues[ACTION_TYPE] === CREATE
    : false)

export const isCanCreateElement = (
  formValues,
) =>
    formValues[ACTION_TYPE] === CREATE
    && formValues[CREATE_TYPE] === GEOZONE ? true :
    formValues.id && formValues.type && (
  (formValues[CREATE_TYPE] === PROJECT
    || formValues[CREATE_TYPE] === INTEGRATION
    || formValues[CREATE_TYPE] === OBJECT_ELEMENT)
    || formValues[ACTION_TYPE] === PUT)

export const formatGeoZoneCreateValues = (card) => ({
  parentId: card.data.treeElementId || card.data.id,
  maxRadius: card.data.maxRadius,
})

export const formatGeoZonePutValues = (card) => ({
  ...card.data,
  lat: setSixDigitsAfterDot(get(card, 'data.point.latitude', null)),
  lon: setSixDigitsAfterDot(get(card, 'data.point.longitude', null)),
  mark: get(card, 'data.note', null),
})

export const formatProjectCreateValues = (card) => ({
  parentId: card.data.id,
  parentName: card.data.name,
})

export const formatProjectPutValues = (card) => ({
  ...card.data,
  parentId: card.data.parentId,
  mark: get(card, 'data.note', null),
})

export const formatObjectCreateValues = (card) => ({
  ...card.data,
  integrationId: get(card, 'data.integrationId', null),
  parentTreeElementId: get(card, 'data.id', null),
  integrationType: get(card, 'data.integrationType', null),
  type: get(card, 'data.installationType', null),
})

export const formatObjectPutValues = (card) => ({
  ...formatDataForObject(card.data),
})

export const formatIntegrationCreateValues = (card) => ({
  parentId: card.data.parentId,
})

export const formatIntegrationPutValues = (card) => ({
  ...card.data,
  parentId: card.data.parentId,
  parentName: card.data.parentName,
  name: card.data.name,
})

export const toBase64 = (file) => new Promise((resolve, reject) => {
  if (!file) {
    return null
  }
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = (loadError) => reject(loadError)
})

export const handlePhotoConvertToBase64 = async (fileValue, setValue) => {
  const base64 = await toBase64(fileValue)
  setValue(base64)
}
