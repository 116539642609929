import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 310px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`
export const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.option};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
`
export const InputAndLabelContainer = styled.div`
  width: 100%;
  padding: 0px 15px;
  padding-top: 15px; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  input {
    width: 100%;
    margin-top: 5px;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
`

export const HeaderContainer = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  flex: 1;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;
  color:  ${({ theme }) => theme.colors.colors.disabled};
  border-right: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  ${({ cancelTitle, theme }) => cancelTitle && `
    text-transform: none;
    color: ${theme.colors.colors.logo};
  `}
  ${({ textAlign }) => textAlign === 'left' && `
    justify-content: flex-start;
    padding-left: 15px;
  `}
`

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.logo};
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.backgrounds.controls.green
    if (type === 'red') return theme.colors.backgrounds.controls.red
    return 'transparent'
  }};
  svg {
    width: 18px;
    height: 18px;
  }
  ${({ deleteButton }) => deleteButton && `
    svg {
      width: 28px;
      height: 28px;
    }
  `}
  cursor: pointer;
  ${({ disabled }) => disabled && `
    pointer-events: none;
    cursor: disabled;
    opacity: 0.5;
  `}
`
export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
`
