import { GET_GROUP_OBJECTS_STRUCTURE_FILE } from '@/store/actions/objectsGroups/index';


export const requestGetGroupObjectsStructureFile = (payload) => ({
  type: GET_GROUP_OBJECTS_STRUCTURE_FILE.REQUEST,
  payload,
})

export const successGetGroupObjectsStructureFile = (payload) => ({
  type: GET_GROUP_OBJECTS_STRUCTURE_FILE.SUCCESS,
  payload,
})

export const errorGetGroupObjectsStructureFile = (payload) => ({
  type: GET_GROUP_OBJECTS_STRUCTURE_FILE.ERROR,
  payload,
})