import React, {
  useCallback,
  useEffect
} from 'react'
import pt from 'prop-types'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'

import PopupIcons from '@/components/icons/popup'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import {
  INTEGRATION,
  ASU,
  OBJECT_TYPES,
  PROCESSES,
  EXPLOITATION_ORGANIZATIONS,
  OWNERS,
  REGISTER_NUMBER,
  STREET,
  VOLS,
  BALANCE_SHEET_HOLDERS,
  LOCATION_TYPE,
  UTILITY_POLE_TYPE,
  LAMP_TYPE,
  CONTROL_CUPBOARD,
} from '@/constants/forms/globalFilterForm'
import {
  Title,
  TitleAndSelectWrapper,
  FormWrapper,
  StyledSelectField,
  StyledCheckBox,
  ButtonsWrapper,
  MultipleCheckBoxWrapper,
  StyledButton,
} from './styles'
import { TITLE_TO_STATUS } from '@/constants/maps'
import PortalTooltip from '@/components/blocks/PortalTooltip'

const GlobalFilterForm = ({
  onSetValues,
  onResetValues,
  globalFiltersValues,
  requestGetSearchFieldsOptions,
  searchFieldsOptions,
  requestGetAllConnectedIntegrations
}) => {

  useEffect(() => {
    requestGetAllConnectedIntegrations()
  }, [requestGetAllConnectedIntegrations])

  const onFieldChange = useCallback((values) => (fieldName, value) => {
    const updatedValues = {
      ...values,
      [fieldName]: value
    }
    requestGetSearchFieldsOptions(updatedValues)
  }, [requestGetSearchFieldsOptions])

  const handleResetFilters = useCallback((resetForm) => (event) => {
    event.preventDefault()
    onResetValues()
    resetForm()
    requestGetSearchFieldsOptions()
  }, [onResetValues, requestGetSearchFieldsOptions])

  // const defaultOptionsByFields = useMemo(() => ({
  //   [ASU]: searchFieldsOptions.integrationType,
  //   [PROCESSES]: searchFieldsOptions.cityProcess,
  //   [INTEGRATION]: searchFieldsOptions.integration,
  //   [OBJECT_TYPES]: searchFieldsOptions.equipmentType,
  // }), [searchFieldsOptions])
  //
  // const fullFilters = useMemo(() => {
  //   if (Object.keys(searchFieldsOptions).length){
  //     return searchFieldsOptions.integrationType.map((integration) => ({
  //       [INTEGRATION]: (connectedIntegrations[integration.value]?.children || [])
  //           .map((integrations) => integrations.installationId),
  //       [ASU]: [integration.value],
  //       [OBJECT_TYPES]: typesByASU[integration.value],
  //       [PROCESSES]: processesByASU[integration.value],
  //     }))
  //   }}, [searchFieldsOptions, connectedIntegrations])
  //
  // const getIncludedValues = useCallback(
  //   (base = [], array = []) => base.filter((value) => array.includes(value)),
  //   [],
  // )
  //
  // const getOptionsByValues = useCallback((field, values, setFieldValue) => {
  //   const currentFieldValue = values[field]
  //   const localFieldsValues = { ...values }
  //   if (values.processes && field !== ASU && (values.asu?.length || values.integrations?.length || values.objectsTypes?.length)) {
  //     delete localFieldsValues['processes']
  //   }
  //   delete localFieldsValues[field]
  //
  //   const localFieldsKeys = Object.keys(localFieldsValues)
  //   const hasLocalFields = localFieldsKeys.length > 0
  //     && localFieldsKeys.some((localField) => !!values[localField].length)
  //
  //   if (!hasLocalFields) {
  //     return defaultOptionsByFields[field]
  //   }
  //
  //   const valuesIdsByFields = localFieldsKeys.reduce((fieldsAccumulator, fieldKey) => {
  //     const fieldValues = values[fieldKey]
  //     const optionsIdsForMap = fullFilters.reduce((accumulator, optionsConfig) => {
  //       const currentFieldOptions = getIncludedValues(optionsConfig[fieldKey], fieldValues)
  //       if (currentFieldOptions && currentFieldOptions.length) {
  //         return [...new Set([...(accumulator || []), ...optionsConfig[field]])]
  //       }
  //       return accumulator
  //     }, [])
  //
  //     return fieldsAccumulator.length ? fieldsAccumulator : [...new Set([...(fieldsAccumulator), ...optionsIdsForMap])]
  //   }, [])
  //
  //   const options = defaultOptionsByFields[field]
  //     .filter((option) => valuesIdsByFields.includes(option.value))
  //   const filteredOriginalValues = (currentFieldValue || [])
  //     .filter((originalValue) => valuesIdsByFields.includes(originalValue))
  //   if (!isEqual(currentFieldValue, filteredOriginalValues)) {
  //     setFieldValue(field, filteredOriginalValues)
  //   }
  //   return options
  // }, [defaultOptionsByFields, fullFilters, getIncludedValues])
  return (
    <Formik
      enableReinitialize
      onSubmit={onSetValues}
      initialValues={globalFiltersValues}
      render={({
        values, handleSubmit, resetForm,
      }) => (
        <FormWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.ASU" />
            </Title>
            <StyledSelectField
                multiselect
                placeholder={<Lang id="globalFilter.selectPlaceholders.ASU" />}
                withSearch
                name={ASU}
                options={searchFieldsOptions.integrationTypes}
                disabled={!searchFieldsOptions.integrationTypes.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.process" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={PROCESSES}
                placeholder={<Lang id="globalFilter.selectPlaceholders.process" />}
                options={searchFieldsOptions.cityProcessTypes}
                disabled={!searchFieldsOptions.cityProcessTypes.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.integration" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={INTEGRATION}
                placeholder={<Lang id="globalFilter.selectPlaceholders.integration" />}
                options={searchFieldsOptions.integrationIds}
                disabled={!searchFieldsOptions.integrationIds.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.objectTypes" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={OBJECT_TYPES}
                placeholder={<Lang id="globalFilter.selectPlaceholders.objectTypes" />}
                options={searchFieldsOptions.installationTypes}
                disabled={!searchFieldsOptions.installationTypes.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.owner" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={OWNERS}
                placeholder={<Lang id="globalFilter.selectPlaceholders.owners" />}
                options={searchFieldsOptions.owner}
                disabled={!searchFieldsOptions.owner.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.exploitationOrganization" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={EXPLOITATION_ORGANIZATIONS}
                placeholder={<Lang id="globalFilter.selectPlaceholders.exploitationOrganizations" />}
                options={searchFieldsOptions.exploitationOrganization}
                disabled={!searchFieldsOptions.exploitationOrganization.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.registerNumber" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={REGISTER_NUMBER}
                placeholder={<Lang id="globalFilter.selectPlaceholders.registerNumbers" />}
                options={searchFieldsOptions.registerNumber}
                disabled={!searchFieldsOptions.registerNumber.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.street" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={STREET}
                placeholder={<Lang id="globalFilter.selectPlaceholders.streets" />}
                options={searchFieldsOptions.street}
                disabled={!searchFieldsOptions.street.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.vols" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={VOLS}
                placeholder={<Lang id="globalFilter.selectPlaceholders.vols" />}
                options={searchFieldsOptions.vols}
                disabled={!searchFieldsOptions.vols.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.balanceSheetHolders" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={BALANCE_SHEET_HOLDERS}
                placeholder={<Lang id="globalFilter.selectPlaceholders.balanceSheetHolders" />}
                options={searchFieldsOptions.balanceSheetHolders}
                disabled={!searchFieldsOptions.balanceSheetHolders.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.locationType" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={LOCATION_TYPE}
                placeholder={<Lang id="globalFilter.selectPlaceholders.locationTypes" />}
                options={searchFieldsOptions.locationType}
                disabled={!searchFieldsOptions.locationType.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.utilityPoleType" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={UTILITY_POLE_TYPE}
                placeholder={<Lang id="globalFilter.selectPlaceholders.utilityPoleTypes" />}
                options={searchFieldsOptions.utilityPoleType}
                disabled={!searchFieldsOptions.utilityPoleType.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.lampType" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={LAMP_TYPE}
                placeholder={<Lang id="globalFilter.selectPlaceholders.lampTypes" />}
                options={searchFieldsOptions.lampType}
                disabled={!searchFieldsOptions.lampType.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="globalFilter.formTitles.controlCupboard" />
            </Title>
            <StyledSelectField
                multiselect
                withSearch
                name={CONTROL_CUPBOARD}
                placeholder={<Lang id="globalFilter.selectPlaceholders.controlCupboards" />}
                options={searchFieldsOptions.controlCupboard}
                disabled={!searchFieldsOptions.controlCupboard.length}
                onAfterChange={onFieldChange(values)}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper isMobile={true}>
            <Title><Lang id="globalFilter.formTitles.objectState" /></Title>
            <MultipleCheckBoxWrapper>
              <PortalTooltip
                  title={TITLE_TO_STATUS[OK]}
                  renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <StyledCheckBox
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                          type={OK}
                          name={OK}
                      />
                  )}
              />
              <PortalTooltip
                  title={TITLE_TO_STATUS[WARNING]}
                  renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <StyledCheckBox
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                          type={WARNING}
                          name={WARNING}
                      />
                  )}
              />
              <PortalTooltip
                  title={TITLE_TO_STATUS[ERROR]}
                  renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <StyledCheckBox
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                          type={ERROR}
                          name={ERROR}
                      />
                  )}
              />
              <PortalTooltip
                  title={TITLE_TO_STATUS[INFO]}
                  renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <StyledCheckBox
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                          type={INFO}
                          name={INFO}
                      />
                  )}
              />
            </MultipleCheckBoxWrapper>
          </TitleAndSelectWrapper>
          <ButtonsWrapper>
            <StyledButton usage="reset" onClick={handleResetFilters(resetForm)}>
              <PopupIcons.ResetFilterIcon color="#3D3D3D" />
              <Lang id="globalFilter.buttons.reset" />
            </StyledButton>
            <StyledButton usage="accept" onClick={handleSubmit}>
              <PopupIcons.DoneFilterIcon color="#FFFFFF" />
              <Lang id="globalFilter.buttons.accept" />
            </StyledButton>
          </ButtonsWrapper>
        </FormWrapper>
      )}
      />
  )
}

GlobalFilterForm.propTypes = {
  cityProcesses: pt.arrayOf(pt.shape({
    name: pt.string,
    id: pt.oneOfType([pt.string, pt.number]),
  })),
  integrationSystems: pt.shape({}),
  onSetValues: pt.func.isRequired,
  onResetValues: pt.func.isRequired,
  globalFiltersValues: pt.shape({
    [ASU]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
    [PROCESSES]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
    [INTEGRATION]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
    [OBJECT_TYPES]: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
  }),
}

GlobalFilterForm.defaultProps = {
  cityProcesses: [],
  integrationSystems: {},
  globalFiltersValues: {},
}

export default GlobalFilterForm
