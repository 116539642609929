import styled from 'styled-components'

export const HeaderContainer = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`
export const Container = styled.div`
  && > div {
    margin: 0px 5px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.colors.white};
  }
  height: 100%;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const IconContainer = styled.div`
  min-width: 48px;
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg,
  .svgFill,
  .svgStroke {
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
  opacity: ${({ disabled }) => disabled ? '0.7':'1'}
}
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.backgrounds.controls.green
    if (type === 'red') return theme.colors.backgrounds.controls.red
    return 'transparent'
  }};
  cursor: pointer;
  cursor: ${({ disabled }) => {
    if(disabled) return 'not-allowed'
  }};
  ${({ theme, border }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
`
export const Title = styled.div`
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  flex: 1;
`

export const TitleAndIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 10px;
  }
  ${Title} {
    padding-left: 0px;
  }
`

export const HeaderPassport = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`
export const ObjectNameRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-left: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};

  @media (max-width: 910px) {
    padding-right: 8px;
  }
`

export const ObjectTitle = styled.div`
  margin: auto;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const ControlsContainer = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 11px;
`
