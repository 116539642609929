import styled from 'styled-components'
import Button from '@/components/blocks/Button'
import { LIGHT_THEME_NAME } from '@/constants/names'
import { Form } from 'formik'

export const Header = styled.div`
  width: 100%;
  ${({ theme }) => theme.themeName === LIGHT_THEME_NAME && `
    background:${theme.colors.backgrounds.redisign.default};
  `}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
`

export const FormContainer = styled(Form)`
  position: relative;
  display: flex;
  align-items: center;
`

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 5;
  .react-datepicker__view-calendar-icon input {
    width: 176px;
  }
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
`
export const InventoryDecorContainer= styled.div`
  box-sizing: border-box;
  display: flex;
  wrap: nowrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
  height: 47px;
  width: 1723px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`

export const LightTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
`

export const InventoryHeaderOne = styled.div`
  height: 100%;
  min-width: 241px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
`

export const InventoryHeaderTwo = styled.div`
  height: 100%;
  min-width: 1061px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InventoryHeaderTree = styled.div`
  height: 100%;
  min-width: 422px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  position: relative;
`

export const Footer = styled.div`
  width: 100%;
  height: 48px;
  z-index: 100;
`

export const TableRow = styled.div`
  width: 100%;
  height: 48px;
`

export const FilterContainer = styled.div`
  height: 47px;
  display: flex;
  align-items: center;
  min-width: 0px;
  padding-left: 10px;
  ${({ settingsOptions }) => settingsOptions && `
    max-width: none;
  `}
`

export const CreateReportContainer = styled.div`
  background: ${({ theme, light }) => {
    if (light) {
      return theme.colors.white
    }
    return theme.colors.backgrounds.redisign.lightGray
  }};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: 4px;
  min-height: 28px;
  min-width: 153px;
  max-height: 28px;
  max-width: 153px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  align-items: center;
  cursor: pointer;
`

export const SearchButton = styled(Button)`
  margin-left: 10px;
  padding: 7px;
  font-family: 'Inter';
  color: #273244;
  background: #EFEFEF;
  text-transform: capitalize;
`

export const Container = styled.div`
  ${TableRow}:nth-child(even) {
  }
`

export const SecondaryTitle = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const CheckBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SecondaryTitle} {
    width: 100%;
    padding-left: 10px;
  }
`
export const StylesWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  position: relative;
  padding-right: 10px;
  * {
    box-sizing: border-box;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    thead {
      top: 0px;
      tr {
      font-family: 'Roboto Mono';
        th {
          height: 70px;
          position: sticky;
          top: 0;
          z-index: 1;
          ${({ isEdge }) => isEdge && `
            min-width: 90px;
          `}
          background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
        }
      }
    }
    tbody{
      max-height: 100%;
    }
    td {
      font-style: normal;
      font-weight: normal;
      font-size: 1.2rem;
      line-height: auto;
      color: ${({ theme }) => theme.colors.colors.default};
      height: 38px;
      ${({ serviceDesk }) => serviceDesk && `
        max-width: 500px;
      `}
    }
    // tr:nth-child(even) {
    //   background: ${({ theme }) => theme.colors.backgrounds.default};
    // }
    tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.borders.redisign.default};
    }
    tr:nth-child(odd) {
      // background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
    } 
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0px;
      // :last-child {
      //   border-right: 0;
      // }
      sub {
        margin-top: 10px;
      }
    }
  }
`

export const DecorativeElement = styled.div`
  width: 12px;
  height: 1px;
  background: ${({ theme }) => theme.colors.colors.default};
  margin: 0 9px;
`

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 48px;
  bottom: 0;
  align-items: flex-end;
  justify-content: space-between;
  ${({ isInfoCardOpen }) => isInfoCardOpen && `
    position: relative;
  `}
`

export const IconContainer = styled.div`
  margin-right: 9px;
  display: flex;
  align-items: center;
`

export const ResetSearchContainer = styled.div`
  background: ${({ theme, light }) => {
    if (light) {
      return theme.colors.white
    }
    return theme.colors.backgrounds.redisign.lightGray
  }};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: 4px;
  min-height: 28px;
  min-width: 153px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  cursor: pointer;
`

export const ResetSearchWrapper = styled.div`
  display: flex;
`

export const TooltipWrapper = styled.div`
  ${ResetSearchWrapper}:hover && {
    display: block
  }
`
