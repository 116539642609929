import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2151_5190)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 7C11.1046 7 12 6.10457 12 5C12 3.89543 11.1046 3 10 3C8.89544 3 8.00001 3.89543 8.00001 5C8.00001 6.10457 8.89544 7 10 7Z" fill={fill} className="svgFill"/>
      <path d="M7.00001 8H3.79289L2 9.79289L2.00001 12H5.00001V10L6 9L7.00001 8Z" fill={fill} className="svgFill"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00001 5.5C7.00001 6.32843 6.32844 7 5.50001 7C4.67159 7 4.00001 6.32843 4.00001 5.5C4.00001 4.67157 4.67159 4 5.50001 4C6.32844 4 7.00001 4.67157 7.00001 5.5Z" fill={fill} className="svgFill"/>
      <path d="M14 11L11 8L9.00001 10V8L6.00001 11V14H14V11Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_2151_5190">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

UserIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserIcon)
