import styled from 'styled-components'

export default styled.div`
  width: 100%;

  .selected-node {
    position: relative;
    background: none;

    &::before {
      content: '';
      position: absolute;
      background: rgba(71, 128, 243, 0.2);
      top: 0;
      bottom: 0;
      left: -5px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
`
