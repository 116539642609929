import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ApplicationsContainer = styled.div`
  width: calc(100vw - 30px);
  display: flex;
  margin: 7.5px 7.5px 7.5px 15px;
  gap: 5px;

  .swiper-slide{
    width: auto !important;
  }

  @media (min-width: 480px){
    .swiper-container {
      overflow: visible;
      margin-left: 0;
    }
  }
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const Slide = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: #3E3C3E;
  text-align: left;
  display: flex
  align-items: center
  gap: 5px;
  height: 24px;
  background: rgba(48, 51, 58, 0.1);
  padding: 5px 6px 5px 6px;
  width: fit-content;
  border-radius: 5px;
  ${({ active }) => active && `
    background: rgba(16, 97, 223, 0.2);
    color: #1061DF
  `};
`


export const Title = styled.div`
  text-wrap: wrap;
  opacity: 0.6;
`

export const Count = styled.div`
  background-color: #FAFBFB;
  border-radius: 5px;
  padding: 1px 3px 1px 3px;
  font-size: 1rem;
  color: #838D99;
  ${({ active }) => active && `
    color: #1061DF
  `};
`