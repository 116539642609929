import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ArrowIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_28_260)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 10H12L8 6L4 10H5.5L8 7.5L10.5 10Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_28_260">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

ArrowIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ArrowIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ArrowIcon)
