import styled from 'styled-components'

export const Container = styled.div`
  min-width: 358px;
  max-width: 358px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #F6F9FB;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};

  @media (max-width: 910px) {
    min-width: 100vw;
    max-width: 100vw;
  }
`

export const ScrollableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const SelectControlContainer = styled.div`
  width: 100%;
  padding: 15px 15px;
  && > div {
    width: 100%
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.default};
  text-transform: uppercase;
  margin-bottom: 10px;
`
export const LoaderContainer = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`
