import styled from 'styled-components'
import InputMask from 'react-input-mask'

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 5px;
  transition: color 100ms ease-in-out;
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.colors.disabled};
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 100%;
  margin-top: 5px;
  transition: color 100ms ease-in-out;
  text-align: left;
  word-break: break-word;
`
export const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 0;
  width: 100%;
  height: 100%;
`

export const FieldStyled = styled(InputMask)`
  outline: none;
  background: ${({ theme }) => theme.colors.backgrounds.textField};
  border: 1px solid ${({ theme, isError }) => (isError
    ? theme.colors.messages.error
    : theme.colors.borders.textField)};
  -webkit-text-fill-color: ${({ theme }) => theme.colors.colors.textField};
  color: ${({ theme }) => theme.colors.colors.textField};
  width: 100%;
  border-radius: 4px;
  height: 28px;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0 10px;
  transition: background 100ms ease-in-out;
  ${({ action }) => action && `
    cursor: pointer;
    && > * {
      cursor: pointer;
    }
  `}

  ::placeholder {
    color: ${({ theme }) => theme.colors.colors.textField};
    ${({ placeholderCenter }) => (placeholderCenter) && `
      text-align: center;
  `}
    ${({ upperCase }) => upperCase && `
      text-transform: uppercase;
  `}
  }

  :disabled {
    border: ${({ theme }) => `1px solid ${theme.colors.backgrounds.textField}`};
    background: ${({ theme }) => theme.colors.backgrounds.textField};
  }

  &[type=number]::-webkit-inner-spin-button, 
  &[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  ${({ styleType, theme, isError }) => styleType === 'secondary' && `
    border: 1px solid
      ${isError ? theme.colors.messages.error : theme.colors.borders.redisign.lightBlack};
    border-radius: 4px;
    height: 44px;
  `}
  ${({ styleType, currentValue }) => styleType === 'secondary' && currentValue && `
    border: none;
    background: none;
    height: 44px;
  `}
  text-overflow: ellipsis;
  ${({ controls }) => controls && `
    padding-right: 60px;
  `}
  ${({ icon }) => icon && `
    padding-left: 30px;
  `}
`

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TextFieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  ${Label} {
    height: 28px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    margin: 0;
    ${({ styleType }) => styleType === 'secondary' && `
      left: 12px;
      top: 8px;
    `}

    ${({ disableDefaultColor, theme }) => !disableDefaultColor && `
      svg {
        & > .svgFill {
          fill: ${theme.colors.backgrounds.disabled};
        }
        & > .svgStroke {
          stroke: ${theme.colors.backgrounds.disabled};
        }
      }
    `}
  }

  ${ControlsContainer} {
    z-index: 3;
    cursor: pointer;
    height: 28px;
    position: absolute;
    right: 0px;
        ${({ disableDefaultColor, theme }) => !disableDefaultColor && `
      svg {
        & > .svgFill {
          fill: ${theme.colors.colors.textFieldActive};
        }
        & > .svgStroke {
          stroke: ${theme.colors.colors.textFieldActive};
        }
      }
    `}
  }

  ${FileInput} {
    z-index: 2;
    ${({ dark, theme }) => dark && `
      background: ${theme.colors.backgrounds.default};
      border-color: ${theme.colors.borders.default};
    `}
  }

  ${({ fieldIcon }) => fieldIcon && `
    ${FileInput} {
      padding-left: 33px;
    }
  `}

  ${({ focused, filled, theme }) => (focused || filled) && `
    ${FileInput} {
      background: ${theme.colors.backgrounds.textFieldActive};
      -webkit-text-fill-color: ${theme.colors.colors.textFieldActive};
      color: ${theme.colors.colors.textFieldActive};
    }
  `}

  ${({ disabled, theme, isError }) => disabled && `
    ${FileInput} {
      border: 1px solid
      ${isError ? theme.colors.messages.error : theme.colors.backgrounds.textField};
      background: ${theme.colors.backgrounds.textField};
    }
  `}
  ${({ theme, gray }) => gray && `
    ${FieldStyled} {
      z-index: 2;
      background: pink;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.8rem;
      background: ${theme.colors.backgrounds.selectInput};
      border-color: ${theme.colors.borders.default};
      color: ${theme.colors.colors.default};
      -webkit-text-fill-color: ${theme.colors.colors.redisign.default};
    }
  `}
`

export const FieldStyledContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const TextInfo = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.0rem;
  line-height: 1.2rem;
  letter-spacing: -0.003em;
  color: ${({ theme }) => theme.colors.colors.dim};
  min-width: 103px;
  text-align: left;
  margin-left: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  z-index: 3;
`
