import styled from 'styled-components'

export const ClusterContainerSmall = styled.div`
  z-index: 120;
  position: relative;
  left: -20px;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #040712;
  border-radius: 50%;
  transition: all 1s ease-out;
  width: 40px;
  height: 40px;
  box-shadow: ${({ theme }) => theme.colors.shadows.pin};
`

export const ClusterContainerMedium = styled.div`
  z-index: 120;
  position: relative;
  left: -30px;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #040712;
  border-radius: 50%;
  transition: all 1s ease-out;
  width: 60px;
  height: 60px;
  box-shadow: ${({ theme }) => theme.colors.shadows.pin};
`

export const ClusterContainerBig = styled.div`
  z-index: 120;
  position: relative;
  left: -40px;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #040712;
  border-radius: 50%;
  transition: all 1s ease-out;
  width: 80px;
  height: 80px;
  box-shadow: ${({ theme }) => theme.colors.shadows.pin};
`

export const PieChartContainerSmall = styled.div`
  position: absolute;
  left: 1px;
  top: 1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  width: 38px;
  height: 38px;
`

export const PieChartContainerMedium = styled.div`
  position: absolute;
  left: 1px;
  top: 1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  width: 58px;
  height: 58px;
`

export const PieChartContainerBig = styled.div`
  position: absolute;
  left: 1px;
  top: 1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  width: 78px;
  height: 78px;
`

export const PieCharOverLaySmall = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  background: #040712;
  z-index: 125;
  width: 30px;
  height: 30px;
`

export const PieCharOverLayMedium = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: #040712;
  z-index: 125;
  width: 40px;
  height: 40px;
`

export const PieCharOverLayBig = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  background: #040712;
  z-index: 125;
  width: 60px;
  height: 60px;
`

export const TitleSmall = styled.div`
  position: absolute;
  left: 0px;
  top: 8px;
  color: ${({ theme }) => theme.colors.colors.light};
  text-align: center;
  z-index: 130;
  width: 30px;
  font-size: 1.2rem;
  line-height: 1.4rem;
`

export const TitleMedium = styled.div`
  position: absolute;
  left: 0;
  top: 12px;
  color: ${({ theme }) => theme.colors.colors.light};
  text-align: center;
  z-index: 130;
  width: 40px;
  font-size: 1.4rem;
  line-height: 1.4rem;
`

export const TitleBig = styled.div`
  position: absolute;
  left: 0px;
  top: 22px;
  color: ${({ theme }) => theme.colors.colors.light};
  text-align: center;
  z-index: 130;
  width: 60px;
  font-size: 1.6rem;
  line-height: 1.6rem;
`
