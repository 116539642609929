import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReverseExclamationIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_105_1830)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM8.5 5V9H7.5V5H8.5ZM8.5 11V10H7.5V11H8.5Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_105_1830">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fill={fill} className="svgFill" clipRule="evenodd" d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM8.76845 11.1004H10.6663L10.9652 3H8.46952L8.76845 11.1004ZM8.76845 12.6391C8.52282 12.8942 8.4 13.2225 8.4 13.6242C8.4 14.0258 8.52282 14.3569 8.76845 14.6174C9.01872 14.8725 9.32923 15 9.7 15C10.0708 15 10.379 14.8725 10.6246 14.6174C10.8749 14.3569 11 14.0258 11 13.6242C11 13.2225 10.8749 12.8942 10.6246 12.6391C10.379 12.3786 10.0708 12.2483 9.7 12.2483C9.32923 12.2483 9.01872 12.3786 8.76845 12.6391Z" />
    // </svg>
  )
}

ReverseExclamationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReverseExclamationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReverseExclamationIcon)
