import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LogoDarkIcon = ({ color, onClick }) => {
  const {
    theme,
  } = useSvgColors(color)
  const fill = theme.colors.colors.logo

  return (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H15V5.5C15.5 5.5 17.5 5.5 19 7V0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H11V17H14.5C16.9853 17 19 14.9853 19 12.5C19 10.0147 16.9853 8 14.5 8H7V24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#41A4FF"/>
    </svg>
    // <svg onClick={onClick} width="60" height="24" viewBox="0 0 60 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M53.446 6.53541H49.1667L49.3249 5.50828L51.5683 3.38351L51.8746 3.07134C52.3589 2.57791 52.5763 2.10462 52.5368 1.67162C52.5071 1.32924 52.3292 1.15805 52.013 1.14798C51.7857 1.13791 51.5979 1.23861 51.4497 1.41987C51.2915 1.6112 51.2026 1.86295 51.1631 2.16505L49.7399 2.17511C49.7597 1.77232 49.8783 1.39973 50.0957 1.06742C50.3131 0.735108 50.5997 0.473289 50.9654 0.281959C51.3311 0.0906298 51.7165 0 52.1217 0C52.7344 0.01007 53.2187 0.181259 53.5547 0.513569C53.8907 0.845878 54.0389 1.28896 53.9994 1.84281C53.9698 2.17511 53.8709 2.49735 53.6832 2.80952C53.4954 3.12169 53.2285 3.454 52.8728 3.79638L52.2106 4.41065L51.2322 5.37737L53.614 5.38744L53.446 6.53541Z" fill="#F3A53A"/>
    //   <path d="M58.3281 4.02794H58.9507L58.753 5.17592H58.1304L57.8932 6.54543H56.4701L56.7073 5.17592H54.3453L54.4244 4.19913L57.5276 0.120786V0.0905762L59.0001 0.110716L58.3281 4.02794ZM55.8277 4.01787L56.9049 4.02794L57.2904 2.03408L55.8277 4.01787Z" fill="#F3A53A"/>
    //   <path d="M22.7354 8.07511V3.6712H18.1237V11.1609C19.7399 10.0574 21.2808 9.03559 22.7354 8.07511Z" fill={fill}/>
    //   <path d="M5.57072 14.0015L9.48207 14.7985C10.5811 15.0131 11.0337 15.3911 11.1738 15.9531C12.5099 15.0131 13.8029 14.1241 15.0528 13.2556C14.2016 12.0907 12.7254 11.3142 10.7751 10.8952L6.89605 10.0778C5.72157 9.84281 5.38754 9.31148 5.38754 8.59622C5.38754 7.58465 6.36807 6.79788 7.89814 6.79788C9.37432 6.79788 10.829 7.61531 11.163 9.29104L15.6885 8.4123C14.9881 5.18345 12.025 3.27271 7.95201 3.27271C3.56655 3.27271 0.549536 5.41846 0.549536 8.86189C0.538761 12.101 2.88772 13.4191 5.57072 14.0015Z" fill={fill}/>
    //   <path d="M3.976 21.0723C5.35521 20.0709 6.7021 19.1104 8.01666 18.1704C6.36807 18.058 5.19359 17.2508 4.75181 15.5648L0 16.4436C0.323252 18.6506 1.84254 20.214 3.976 21.0723Z" fill={fill}/>
    //   <path d="M22.7354 12.2849C21.1084 13.0104 19.5675 13.7563 18.1237 14.5022V21.4299H31.9589V17.6187H22.7354V12.2849Z" fill={fill}/>
    //   <path d="M48.9404 8.4123L44.4149 9.29104C44.0808 7.62552 42.6262 6.79787 41.15 6.79787C39.6092 6.79787 38.6394 7.59487 38.6394 8.59622C38.6394 9.31147 38.9735 9.8428 40.1479 10.0778L44.027 10.8952C47.2703 11.5798 49.1667 13.2556 49.1667 16.0655C49.1667 19.8563 45.6217 21.8182 41.4517 21.8182C37.2279 21.8182 33.7691 19.9074 33.2627 16.4436L38.0145 15.5648C38.4886 17.3938 39.8355 18.1908 41.6996 18.1908C43.4343 18.1908 44.4687 17.4449 44.4687 16.3618C44.4687 15.5648 44.0808 15.0641 42.734 14.7985L38.8226 14.0015C36.1396 13.4191 33.7907 12.0907 33.7907 8.86189C33.7907 5.41846 36.8077 3.27271 41.1931 3.27271C45.2769 3.27271 48.24 5.18345 48.9404 8.4123Z" fill={fill}/>
    //   <path d="M6.23877 21.6751C6.86372 21.767 7.521 21.8181 8.18906 21.8181C12.3482 21.8181 15.904 19.8563 15.904 16.0655C15.904 15.9326 15.904 15.7998 15.8932 15.667C11.6048 18.0069 8.29681 20.2037 6.23877 21.6751Z" fill={fill}/>
    //   <path d="M3.73238 24C5.95211 22.2991 17.4207 13.9189 33.8108 8.89913C34.0686 5.3106 36.807 3.38622 41.307 3.2727C41.71 3.28164 41.922 3.28303 42.8115 3.38C40.0356 1.92694 39.831 1.09979 39.831 1.09979C39.831 1.09979 36.4902 -0.0410767 2.1853 23.5021L3.73238 24Z" fill="url(#paint0_linear_3743_170015)"/>
    //   <defs>
    //   <linearGradient id="paint0_linear_3743_170015" x1="40.2804" y1="2.05053" x2="5.57121" y2="26.931" gradientUnits="userSpaceOnUse">
    //   <stop stop-color="#F7D43D"/>
    //   <stop offset="1" stop-color="#E73930"/>
    //   </linearGradient>
    //   </defs>
    // </svg>
  )
}

LogoDarkIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LogoDarkIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LogoDarkIcon)
