import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const NotificationsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg id="notification"onClick={onClick}  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2151_5231)">
      <path d="M9.5 13C9.5 13.5523 9.05228 14 8.5 14H7.5C6.94772 14 6.5 13.5523 6.5 13H9.5Z" fill={fill} className="svgFill" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C11.2091 3 13 4.79086 13 7V11H14V12H2V11H3V7C3 4.79086 4.79086 3 7 3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3Z" fill={fill} className="svgFill" />
      </g>
      <defs>
      <clipPath id="clip0_2151_5231">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

NotificationsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

NotificationsIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(NotificationsIcon)
