import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const Header = styled.div`
  min-height: 48px;
  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`

export const SecondaryTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
`

export const CheckBoxContainer = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 5px 5px 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SecondaryTitle} {
    padding-left: 10px;
  }
`

export const BulletPoint = styled.div`
  width: 18px;
  height: 18px;
  background: ${({ theme, type }) => theme.colors.backgrounds.chart[type]};
  border-radius: 50%;
  margin-right: 10px;
  `

export const LegendElement = styled.div`
  color: ${({ theme }) => theme.colors.colors.default}
  padding: 0 15px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`
