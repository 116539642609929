import { takeLatest, put} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_GROUP_OBJECTS_STRUCTURE_FILE_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_GROUP_OBJECTS_STRUCTURE_FILE } from '@/store/actions/objectsGroups'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob';
import moment from 'moment';
import getTimeZoneOffset from '@/helpers/getTimeZoneOffset';
import { successGetGroupObjectsStructureFile } from '@/store/actions/objectsGroups/getGroupStructureFile';

function* getGroupObjectsStructureFileSaga({payload}) {
  const toast = createNotifications()

  try {
    const response = yield request({
      url: GET_GROUP_OBJECTS_STRUCTURE_FILE_URL({
        params: {
          groupId: payload.id,
          timeZoneOffset: getTimeZoneOffset(),
        },
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `Список объектов группы ${payload.name} за ${moment(new Date()).format('DD.MM.YYYY')}.xlsx`,
    })

    yield put(successGetGroupObjectsStructureFile())
  } catch (error) {
    console.log(error)
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить группу объектов.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_GROUP_OBJECTS_STRUCTURE_FILE.REQUEST, getGroupObjectsStructureFileSaga)
}
