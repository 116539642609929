import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  width: 420px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  flex: 1;
`

export const Header = styled.div`
  padding: 15px 10px;
  width: 100%;
  height: 49px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  && > div:last-child {
    justify-self: flex-end;
  }
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const ContentContainer = styled.div`
 width: 100%;
 height: calc(100% - 48px);
 overflow-x: hidden;
 overflow-y: auto;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  min-height: 48px;
`

export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  .svgFill { 
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
  cursor: pointer;
  ${({ arrow, theme }) => arrow && `
    border-right: 1px solid ${theme.colors.borders.default}
    width: auto;
    padding-right: 5px;
    margin-right: 10px;
    svg {
      width: 18px;
    }
    .svgFill {
      height: 14px;
      width: 14px;

    }
  `}
  ${({ pr }) => pr && `
    margin-right: ${pr}px;
  `}
`

export const TabHeader = styled.div`
  display: flex;
  height: 48px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const TabButton = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  padding: 5px 10px;
  ${({ active, theme }) => active && `
    border-bottom: 2px solid ${theme.colors.borders.bluePrimary300};
  `}
  min-width: 103px;
  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: 0.7;
  `}
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.colors.dim};
  ${({ active, theme }) => active && `
    color: ${theme.colors.colors.bluePrimary300};
  `}
`