export default {
  catalogs: {
    // controller cart
    location: 'местоположение',
    objectCharacteristics: 'характеристики объекта',
    expertiseData: 'данные из обследования',
    objectService: 'обслуживание объекта',
    // controller passport
    generalInfo: 'общая информация',
    technicalLimitations: 'технические параметры',
    exploitation: 'эксплуатация',
    inner: 'вложения',
  },
  fields: {
    // controller cart
    'LOCATION.PROJECT': 'Проект',
    'LOCATION.LATITUDE': 'Географическая широта',
    'LOCATION.LONGITUDE': 'Географическая долгота',
    'LOCATION.REGION': 'Регион',
    'LOCATION.CITY': 'Город',
    'LOCATION.STREET': 'Улица',
    'LOCATION.HOME_NUMBER': 'Дом',
    'location.project': 'Проект',
    'location.latitude': 'Географическая широта',
    'location.longitude': 'Географическая долгота',
    'location.region': 'Регион',
    'location.city': 'Город',
    'location.street': 'Улица',
    'location.homeNumber': 'Дом',
    'objectCharacteristics.equipmentType': 'Тип оборудования',
    'objectCharacteristics.lampType': 'Тип светильника',
    'objectCharacteristics.lightingCategory': 'Категория объекта освещения',
    'objectCharacteristics.importance': 'Важность объекта',
    'objectCharacteristics.workMode': 'Режим работы',
    'objectCharacteristics.installationType': 'Тип установки',
    'objectCharacteristics.controlType': 'Тип управления',
    'objectCharacteristics.lightingType': 'Тип объекта освещения',
    'objectCharacteristics.outgoingLinesNumber': 'Количество отходящих линий',
    'objectCharacteristics.setup': 'Настройка',
    'objectCharacteristics.indicators': 'Индикаторы',
    'objectCharacteristics.connectedLampNumber': 'Количество подключенных осветительных приборов',
    'objectCharacteristics.locationType': 'Признак пешеходного пространства',
    'expertiseData.connectedLampNumber': 'Количество подключенных осветительных приборов',
    'expertiseData.locationType': 'Признак пешеходного пространства',
    'expertiseData.lampInstallationHeight': 'Высота установки светильника в метрах',
    'expertiseData.roadwayWidth': 'Ширина проезжей части в метрах',
    'expertiseData.installationScheme': 'Схема установки опоры',
    'expertiseData.state': 'Состояние опоры',
    'expertiseData.powerSupply': 'Питание',
    'expertiseData.powerSupplyType': 'Тип питания',
    'expertiseData.overhangHeight': 'Свес световой точки в метрах',
    'expertiseData.lampAngle': 'Наклон световой точки в градусах',
    'expertiseData.overhangLength': 'Длина консоли в метрах',
    'expertiseData.utilityPoleLampPosition': 'Позиция светильника в плане на опоре в градусках',
    'objectService.inspectionDate': 'Дата осмотра',
    'objectService.bypassDate': 'Дата объезда',
    'objectService.maintenanceDate': 'Дата технического обслуживания',
    'objectService.repairDate': 'Дата ремонта',
    'objectService.capitalRepairDate': 'Дата капитального ремонта',
    'objectService.guaranteeExpirationDate': 'Дата окончания гарантии',
    'objectService.checkingDate': 'Дата поверки',

    // controller passport
    'generalInfo.identifier': 'Идентификатор',
    'generalInfo.name': 'Наименование',
    'generalInfo.installation': 'Монтаж',
    'generalInfo.equipmentType': 'Тип оборудования',
    'generalInfo.versionApi': 'Версия ПО (API)',
    'generalInfo.utilityPoleType': 'Тип опоры',
    'generalInfo.modelMark': 'Модель/марка',
    'generalInfo.lampType': 'Тип светильника',
    'generalInfo.manufacturer': 'Производитель',
    'generalInfo.producerSupplier': 'Производитель',
    'generalInfo.lightingControlBoxIdentifier': 'Идентификатор ШУО',
    'generalInfo.automatedControlSystemIdentifier': 'Идентификатор АСУ',
    'generalInfo.lampIdentifier': 'Идентификатор светильника',
    'generalInfo.supportIdentifier': 'Идентификатор опора',
    'generalInfo.lineIdentifier': 'Идентификатор линии',
    'generalInfo.baseStationIdentifier': 'Индентификатор базовой станции',
    'generalInfo.utilityPoleIdentifier': 'Индентификатор опоры',
    'generalInfo.parentIdentifier': 'Идентификатор родителя',
    'generalInfo.parentType': 'Тип родителя',
    'generalInfo.atmosphereTemperature': 'Температура атмосферы',
    'generalInfo.atmospherePressure': 'Атмосферное давление',
    'technicalLimitations.workingTemperatureRange': 'Диапазон рабочей температуры',
    'technicalLimitations.powerConsumption': 'Потребляемая мощность',
    'technicalLimitations.workingVoltageRange': 'Диапазон рабочего напряжения',
    'technicalLimitations.lightOutput': 'Световой поток',
    'technicalLimitations.calibrationInterval': 'Межповерочный интервал',
    'technicalLimitations.colourTemperature': 'Цветовая температура',
    'technicalLimitations.colourRenderingIndex': 'Индекс цветопередачи',
    'technicalLimitations.lampEfficiency': 'Эффективность светильника',
    'technicalLimitations.scatteringAngle': 'Угол рассеивания',
    'technicalLimitations.lightIntensityDistributionCurveType': 'Тип КСС',
    'technicalLimitations.flickerPercent': 'Коэффицент пульсации',
    'technicalLimitations.ratedPower': 'Разрешенная мощность',
    'technicalLimitations.electricitySupplier': 'Поставщик электрической энергии',
    'technicalLimitations.actualConsumptionPower': 'Фактически подключенная мощность',
    'technicalLimitations.temperature': 'Температура в трубопроводе',
    'technicalLimitations.pipeDiameter': 'Условный проход трубы',
    'technicalLimitations.dealTemperature': 'Договорное значение температуры холодной воды',
    'technicalLimitations.dealPressure': 'Договорное значение давления холодной воды',
    'technicalLimitations.tubeDealPressure': 'Договорное значение давления в трубопроводе',
    'technicalLimitations.massFlow': 'Массовый расход в трубопроводе',
    'technicalLimitations.volumetricFlow': 'Объемный расход в трубопроводе',
    'technicalLimitations.totalVolume': 'Объем потребленного ресурса',
    'technicalLimitations.workTime': 'Время работы',
    'technicalLimitations.conventionalStraitPipeline': 'Условный проход трубы в подающем трубопроводе',
    'technicalLimitations.conventionalReversPipeline': 'Условный проход трубы в обратном трубопроводе',
    'technicalLimitations.temperatureInPipe': 'Температура в подающем трубопроводе',
    'technicalLimitations.temperatureInReversePipe': 'Температура в обратном трубопроводе',
    'technicalLimitations.totalMassInPipe': 'Масса в подающем трубопроводе',
    'technicalLimitations.totalMassInReversePipe': 'Масса в обратном трубопроводе',
    'technicalLimitations.massFlowInPipe': 'Массовый расход в подающем трубопроводе',
    'technicalLimitations.massFlowInReversePipe': 'Массовый расход в обратном трубопроводе',
    'technicalLimitations.volumetricFlowInPipe': 'Объем в подающем трубопроводе',
    'technicalLimitations.volumetricFlowInReversePipe': 'Объем в обратном трубопроводе',
    'technicalLimitations.straitPipelineVolumeConsumption': 'Объемный расход в подающем трубопроводе',
    'technicalLimitations.reversePipelineVolumeConsumption': 'Объемный расход в обратном трубопроводе',
    'technicalLimitations.consumedVolume': 'Объем потребленного ресурса',
    'technicalLimitations.energy': 'Тепловая энергия',
    'technicalLimitations.heatCosts': 'Расходы на теплоснабжение',
    'technicalLimitations.economicImportanceOfOverheat': 'Экономическое значение перетопа',
    'technicalLimitations.detectionOfUnderheat': 'Выявление недотопа',
    'technicalLimitations.detectionOfOverheat': 'Выявление перетопа',
    'exploitation.startDate': 'Дата начала эксплуатации',
    'exploitation.organization': 'Эксплуатирующая организация',
    'exploitation.owner': 'Собственник',
    'exploitation.guaranteePeriod': 'Гарантийный срок',
    'exploitation.lifeTime': 'Срок службы',
    'exploitation.totalNumberOfConnectedLightingDevices': 'Общее количество подключенных осветительных приборов',
    'inner.photo': 'Фото',
    'inner.file': 'Файл паспорта изделия',
    'plan.date' : 'Дата плана ППР',
    'fact.date' : 'Дата факта ППР',


    'objectCharacteristics.tariffOne': 'Тариф1',
    'objectCharacteristics.tariffTwo': 'Тариф2',
    'objectCharacteristics.tariffThree': 'Тариф3',
    'objectCharacteristics.tariffFour': 'Тариф4',


  },
  title: 'Цифровой двойник',
  changeTitle: 'Сохранить изменения?',
  passport: 'Паспорт',
  card: 'Карточка',
  composition: 'Состав',
  passportSidebar: {
    equipment: 'оборудование инсталляции',
    discardAll: 'Отметить все',
    passport: 'Паспорт',
    card: 'Карточка',
    composition: 'Состав',
    view: 'Просмотр:',
    noImage: 'изображение отсутствует',
    passportExport: 'экспорт паспорта',
    fieldSettings: 'Настройки отображения полей:',
    yes: 'Да',
    no: 'Нет',
  },
}
