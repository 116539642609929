import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import useReduxTable from '@/hooks/useReduxTable'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import { LIVE_TIME_REPORT_TABLE } from '@/store/actions/lifeTimeReport'
import { getTableParameters } from '@/store/selectors/lifeTimeReport'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'
import Graph from '@/components/charts/Graph'
import MapIcons from '@/components/icons/maps'
import CollapsedInfoBlock from '@/components/blocks/CollapsedInfoBlock'
import { GRAPH_PERCENT, GRAPH_YEAR } from '@/components/charts/Graph/config'
import {
  liveTimeExportOptions,
  liveTimeFields,
} from '@/constants/tablesConfig/liveTime'
import {
  Main,
  Content,
} from './styles'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'

const LiveTime = ({
  data,
  graphType,
  fileStatus,
  node,
  loadingTable,
  globalLoading,

  setGraphType,
  setNode,
  getGraphData,
  getTableData,
  getReportFile,
  graphPercent,
  graphYear,
  selectedGraphPercentOptions,
  selectedGraphYearOptions,
  setNewGraphYearOptions,
  setNewGraphPercentOptions,
  intl,
  getObjectTypesOptions,
  objectTypesOptions,
  setObjectTypes,
  objectTypes,
  setGoToPassportization,
  requestGetCard,
}) => {
  useHtmlTitle(intl.messages['menu.life'])
  const [isGraphOpen, setIsGraphOpen] = useState(true)
  const [blockedRequest, setBlockedRequest] = useState(false)
  const isHasDataForGraph = useMemo(() => {
    const dataByType = {
      [GRAPH_PERCENT]: graphPercent,
      [GRAPH_YEAR]: graphYear,
    }
    const selectedByType = {
      [GRAPH_PERCENT]: selectedGraphPercentOptions,
      [GRAPH_YEAR]: selectedGraphYearOptions,
    }
    const graphData = dataByType[graphType]
    const selectedData = selectedByType[graphType]

    return Object.keys(graphData).some((key) => !!graphData[key].indicatorWidth)
      && selectedData.length === 0
  },
  [graphPercent, graphType, graphYear, selectedGraphPercentOptions, selectedGraphYearOptions])

  const getPageData = useCallback(
    () => {
      getGraphData()
      getTableData()
    },
    [
      getGraphData,
      getTableData,
    ],
  )

  useEffect(() => {
    getObjectTypesOptions()
  }, [getObjectTypesOptions])


  useEffect(() => {
    if (node && node.id && !blockedRequest) {
      getPageData()
    }
  }, [getPageData, node, getObjectTypesOptions, objectTypes, blockedRequest])

  const tableProps = useReduxTable(LIVE_TIME_REPORT_TABLE, getTableParameters)

  const handleObjectClick = (item, collumnId) => {
    if (!item) {
      return
    }
    setBlockedRequest(true)
    setGoToPassportization(true)
    requestGetCard({id: item.id, isLiveTime: true, collumnId})
  }

  const toggleShowCase = () => {
    setIsGraphOpen(!isGraphOpen)
  }

  const handleFileDownload = useCallback((fileType) => {
    getReportFile({
      fileType
    })
  }, [getReportFile])

  const onSelectNode = useCallback((selectedNode, event) => {
    if (event === 'click' && selectedNode.id !== node.id) {
      setNode(selectedNode)
    }
  }, [setNode, node])

  return (
    <Main>
      <TreeSidebar
          title="Контроль износа и планирование ремонтов"
          onSelect={onSelectNode}
          hidden={false}
          selectedNode={node}
      />
      <Content>
        <CollapsedInfoBlock
          isSettingsOpen={isGraphOpen}
          setIsSettingsOpen={toggleShowCase}
          name={node.name}
          icon={MapIcons.ArrowIcon}
          content={(
            <Graph
              graphType={graphType}
              setGraphType={setGraphType}
              graphPercent={graphPercent}
              graphYear={graphYear}
              selectedGraphPercentOptions={selectedGraphPercentOptions}
              selectedGraphYearOptions={selectedGraphYearOptions}
              setSelectedGraphYearOptions={setNewGraphYearOptions}
              setSelectedGraphPercentOptions={setNewGraphPercentOptions}
            />
          )}
        />
        {loadingTable
          ? <Loader center />
          : (
            <DutyTable
              {...tableProps}
              isNoData={!isHasDataForGraph}
              fields={liveTimeFields}
              exportOptions={liveTimeExportOptions}
              data={data}
              onUpdateData={getPageData}
              onFileSelect={handleFileDownload}
              fileStatus={fileStatus}
              headerHeight={42}
              isDataLoading={globalLoading}
              liveTimeObjectTypesOptions={objectTypesOptions}
              liveTimeObjectTypes={objectTypes}
              setLiveTimeObjectTypes={setObjectTypes}
              rowSelector={handleObjectClick}
            />
          )}
      </Content>
    </Main>
  )
}


LiveTime.propTypes = {
  data: pt.arrayOf(pt.object),
  tree: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    name: pt.string,
  }),
  graphType: pt.string,
  fileStatus: pt.string,
  node: pt.shape({
    id: pt.oneOfType([pt.number, pt.string]),
    name: pt.string,
  }),
  telemetry: pt.shape({}),
  loadingTable: pt.bool,

  setGraphType: pt.func,
  setNode: pt.func,
  getGraphData: pt.func,
  getTableData: pt.func,
  getReportFile: pt.func,
  graphPercent: pt.arrayOf(pt.object),
  graphYear: pt.arrayOf(pt.object),
  selectedGraphPercentOptions: pt.arrayOf(pt.object),
  selectedGraphYearOptions: pt.arrayOf(pt.object),
  setNewGraphYearOptions: pt.func,
  setNewGraphPercentOptions: pt.func,
}

LiveTime.defaultProps = {
  data: {},
  tree: {},
  graphType: null,
  fileStatus: null,
  node: {},
  loadingTable: false,

  setGraphType: noop,
  setNode: noop,
  getGraphData: noop,
  getTableData: noop,
  getReportFile: noop,
  graphPercent: [],
  graphYear: [],
  selectedGraphPercentOptions: [],
  selectedGraphYearOptions: [],
  setNewGraphYearOptions: noop,
  setNewGraphPercentOptions: noop,
}

export default LiveTime
