import styled from 'styled-components'
import {
  RadioButtonContainer,
} from '@/components/controls/RadioButton/styles'
import LabelWithIcon from '@/components/blocks/LabelWithIcon/styles'
import { Form } from 'formik'

export const StyledForm = styled(Form)`
  height: 100%;
  max-height: 100%;
`

export const AddFieldButton = styled.div`
  width: 100%;
  padding: 10px;
  .svgFill {
    fill: ${({ theme }) => theme.colors.white};
    height: 18px;
    width: 18px;
  }
  svg {
    margin-right: 15px;
  }
  color: ${({ theme }) => theme.colors.white};
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.scheme.backgrounds.default};
  &&:hover {
    background: ${({ theme }) => theme.colors.scheme.backgrounds.hover};
  }
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
  cursor: pointer;
`

export const Container = styled.div`
  width: 310px;
  min-width: 310px;
  height: 100%;
  max-height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const Header = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid  ${({ theme }) => theme.colors.borders.default};
  button {
    height: 28px;
    display: flex;
    align-items: center;
  }
  div:not(:first-child) {
    justify-self: flex-end;
    margin-left: 10px;
  }
  div:first-child {
    flex: 1;
  }
`

export const HeaderTitle = styled.div`
  width: 30%;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: left;
  white-space: wrap;
`

export const FieldsContainer = styled.div` 
  width: 100%;
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  ${({ ghostMode }) => ghostMode && `
    opacity: 0.3;
    cursor: not-allowed;
    position: relative;
    top: 0;
    left: 0;
    ::after {
      position: absolute;
      width: 100%;
      height: calc(100vh - 96px);
      content: ' ';
      top: 0;
      left: 0;
    }
  `}

`
export const StyledButton = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.white};
  svg,
  .svgFill,
  .svgStroke {
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.messages.fix
    if (type === 'red') return theme.colors.messages.critical
    return 'transparent'
  }};
  pointer-events: ${({ isSubmitting }) => {
    return isSubmitting ? 'none' : 'auto'
  }};
  cursor: pointer;
  ${({ theme, border }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
`
export const CancelButton = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.backgrounds.green};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.backgrounds.green};
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  height: 25px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CustomFieldBlock = styled.div`
  width: 100%;
`

export const CatalogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const InfoField = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-transform: uppercase;
`
export const InfoContainer = styled.div`
  padding: 10px 0;
`

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ type, theme }) => type && `
    svg,
    .svgFill {
      height: 14px;
      width: 14px;
      margin-right: 10px;
      fill: ${theme.colors.colors.redisign.dark};
    }
  `}
`

export const CatalogRow = styled.div`
  flex: 1;
  width: 100%;  
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const CatalogTitle = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-transform: uppercase;
  padding-left: 10px;
  text-align: left;
`
export const ToggleContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
      transform-origin: center;
      transform: rotate(180deg);
  }
  ${({ isOpen }) => isOpen && `
    svg {
      transform-origin: center;
      transform: rotate(0deg);
    }
  `}
`
export const CatalogOptionsContainer = styled.div`
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};


`
export const ButtonContainer = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .svgFill {
    height: 16px;
    fill: ${({ theme }) => theme.colors.white};
  }
  svg {
    margin-right: 10px;
  }
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.4rem;
`

export const InputAndLabelContainer = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  && > div {
    text-align: left;
  }
  input {
    width: 100%;
    height: 28px;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
  ${RadioButtonContainer} {
    padding: 5px 0;
  }
  & > span {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: green;
  }
  ${LabelWithIcon} {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.colors.colors.redisign.default};
    margin-bottom: 5px;
  }

`

export const ShortCadTitle = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  padding: 20px 10px 10px;
  box-shadow: 0 10px 23px rgba(0, 0, 0, 0.12);
  border: 1px solid ${({ theme }) => theme.colors.backgrounds.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  ${IconContainer} { 
    height: auto;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`
export const CustomFieldContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  padding: 20px 10px 10px;
  margin: 5px 0;
  box-shadow: 0 10px 23px rgba(0, 0, 0, 0.12);
  border: 1px solid ${({ theme }) => theme.colors.backgrounds.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.table.dark};
  ${IconContainer} { 
    height: auto;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  && > div:last-child {
    padding-top: 10px;
  }
  ${({ remove, theme }) => remove && `
    height: 44px;
    padding: 0px 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${theme.colors.colors.default};
    background: ${({ theme }) => theme.colors.backgrounds.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    && > div:last-child {
      padding-top: 0px;
    }
  `}
`
