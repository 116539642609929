import styled from 'styled-components'

export const WidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 7.5px;

  @media (max-width: 910px) {
    padding-top: 50px;
  }
  
`

export const StaticWidgetsContainer = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StaticWidgetsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const CustomWidgetsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  
  @media (max-width: 910px) {
    justify-content: center
    width: 100%;
  }
`

export const CustomWidgetWrapper = styled.div`
  max-width: 332px;
  max-height: 356px;
  width: 332px;
  height: 356px;
  margin: 7.5px;
  border-radius: 10px;
  background: #F6F9FB;


  @media (max-width: 910px) {
      width: calc(100vw - 30px);
  }
`

export const ApplicationsContainer = styled.div`
  width: calc(100vw - 30px);
  min-height: 72px;
  display: flex;
  justify-content: space-evenly
  margin: 7.5px;
  gap: 10px;
`

export const NotificationsContainer = styled.div`
  position: relative;
  width: calc(100vw - 30px);
  min-height: 86px;
  margin: 10px 0 10px 7.5px;
`
export const NotificationsIconContainer = styled.div`
  position: absolute;
`
export const Title = styled.h1`
  font-size: 24px;
  color: #1061DF;
  padding-left: 10px;
`
export const TitleWidgets = styled.h1`
  font-size: 16px;
  color: #273244;
  padding-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0.7;
`

export const HeaderPage = styled.div`
  width: 100vw;
  display: flex;
  height: 50px;
  align-items: flex-end
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 5px 10px 0;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #FAFBFB;
  z-index: 100;

  & > svg {
    cursor: pointer;
  }
`
