import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const SettingsIcon = ({ color, onClick, className }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_73_1653)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2H9V3.61151C9.50236 3.7255 9.9728 3.92354 10.3958 4.19006L11.5355 3.05029L12.9497 4.46451L11.81 5.60428C12.0765 6.02722 12.2745 6.49765 12.3885 7H14V9H12.3885C12.2745 9.50237 12.0765 9.97282 11.8099 10.3958L12.9497 11.5356L11.5355 12.9498L10.3957 11.81C9.97276 12.0765 9.50234 12.2745 9 12.3885V14H7V12.3885C6.49765 12.2745 6.02722 12.0765 5.60428 11.81L4.46445 12.9498L3.05024 11.5356L4.19006 10.3958C3.92354 9.9728 3.7255 9.50236 3.61151 9H2V7H3.61151C3.7255 6.49766 3.92352 6.02724 4.19002 5.6043L3.05023 4.46451L4.46445 3.05029L5.60423 4.19007C6.02718 3.92354 6.49763 3.7255 7 3.61151V2ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill={fill} className={`${className} svgFill`}/>
      </g>
      <defs>
      <clipPath id="clip0_73_1653">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

SettingsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
  className: pt.string,
}

SettingsIcon.defaultProps = {
  color: null,
  onClick: noop,
  className: null,
}

export default memoWithName(SettingsIcon)
