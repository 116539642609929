export default {
  title: 'Инвентаризация и учет',
  takeInventory: 'Провести инвентаризацию',
  projectName: 'Название проекта:',
  dateInventory: 'Даты проведения инвентаризации:',
  createInventory: 'Сформировать инвентаризационную ведомость',
  table: {
    number: '№ п/п',
    registerNumber: 'Реестровый номер',
    asuno: 'АСУНО, шт',
    controllerFirstIntegration: 'ШУНО, шт',
    baseStationFirstIntegration: 'Базовая станции, шт',
    lampModuleFirstIntegration: 'Модули, шт',
    utilityPoleFirstIntegration: 'Опоры, шт',
    lightFixtureFirstIntegration: 'Светильники, шт',
    cableSewers: 'Кабельные канализации, шт',
  }
}