import React, { useState, useEffect } from 'react'

import { FormattedMessage as Lang } from 'react-intl'
import YandexMap from '@/components/blocks/YandexMap'
import Icons from '@/components/icons/users'
import DatePickerField from '@/components/fields/DatePickerField'
import { Formik } from 'formik'
import {
  DATE_FIRST,
  DATE_SECOND,
} from '@/constants/forms/analyticExpenses'

import DutyTable from '@/components/blocks/DutyTable'
import useReduxTable from '@/hooks/useReduxTable'
import { INVENTORY_TABLE } from '@/store/actions/inventory'
import { getTableParameters } from '@/store/selectors/inventory'
import { fields } from '@/constants/tablesConfig/inventory'







import {
  Main,
  Content,
  MapsContainer,
  Header,
  ButtonContainer,
  TakeInventoryButton,
  InventoryForm,
  HeaderRow,
  Title,
  CloseIconContainer,
  InventoryFormContent,
  ProjectNameWrapper,
  DateInventoryContainer,
  ProjectNameContainer,
  DateInventoryWrapper,
  DatePickerContainer,
  DecorElement,
  CreateInventoryButton,
  ButtonWrapper,
  InventoryContainer,
  TableContainer,
  ToolTipInventory,
  ToolTipInventoryTitle,
  LoaderWrapper,
} from './styles'

import { MAP_SERVICE } from '@/constants/applications'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'
import { exportOptions } from '@/constants/tablesConfig/inventory';


const Inventory = ({
  selectedNode,
  userApplications,
  displayElements,
  requestGetPinsAndZonesAndTelemetry,
  zones,
  requestInventoryTable,
  tableData,
  requestTotalResults,
  totalResults,
  loading,
  setDatesRange,
  dates,
  getFile,
  fileStatus
}) => {
  const [createMode, setCreateMode] = useState(false)
  const [showData, setShowData] = useState(false)

  useEffect(() => {
    if (selectedNode.id) {
      requestGetPinsAndZonesAndTelemetry({
        parentTreeId: selectedNode.path[1]|| selectedNode.id,
      })
    }
  }, [selectedNode, requestGetPinsAndZonesAndTelemetry])

  const tableProps = useReduxTable(INVENTORY_TABLE, getTableParameters)

  const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0]?.settings
  const handleSetNode = () => {

  }
  const onSubmit = (values) => {
    setDatesRange(values)
    setShowData(true)
    requestInventoryTable()
    requestTotalResults()
  }

  return (
    <Main>
      <Content>
        {createMode
          ? 
          <InventoryContainer>
            <InventoryForm>
              <HeaderRow>
                <Title>
                  <Lang id="inventory.takeInventory"/> 
                </Title>
                <CloseIconContainer onClick={() => setCreateMode(false)} >
                  <Icons.CrossIcon />
                </CloseIconContainer>
              </HeaderRow>
              <InventoryFormContent>
                <ProjectNameContainer>
                  <Lang id="inventory.projectName"/>
                  <ProjectNameWrapper>
                    {'Концессия 2021'}
                  </ProjectNameWrapper>
                </ProjectNameContainer>
                <DateInventoryContainer>
                  <Formik
                    initialValues={{
                      [DATE_FIRST]: dates.dateFirst,
                      [DATE_SECOND]: dates.dateSecond,
                    }}
                    enableReinitialize
                    onSubmit={onSubmit}
                    render={({ values: formValues, handleSubmit, dirty }) => {
                      if (!formValues[DATE_FIRST] && !formValues[DATE_SECOND] && dirty) {
                        onSubmit({
                          ...formValues
                        })
                      }
                    return (
                      <>
                      <DateInventoryWrapper>
                        <Lang id="inventory.dateInventory"/>
                      </DateInventoryWrapper>
                      <DatePickerContainer>
                        <DatePickerField 
                          name={DATE_FIRST}
                        />
                        <DecorElement>
                          {','}
                        </DecorElement>
                        <DatePickerField
                          name={DATE_SECOND}
                        />
                      </DatePickerContainer>
                      {(formValues[DATE_FIRST] || formValues[DATE_SECOND]) && dirty && (
                        <ButtonWrapper>
                          <CreateInventoryButton onClick={handleSubmit}>
                            <Lang id="inventory.createInventory" />
                          </CreateInventoryButton>
                        </ButtonWrapper>
                        )}
                      </>
                    )}}
                  />
                  </DateInventoryContainer>
              </InventoryFormContent>
            </InventoryForm>
          <TableContainer>
            {
              !showData ? 
              <>
                <Header>
                </Header>
                <ToolTipInventory>
                  <ToolTipInventoryTitle>
                    {'Выберите даты и нажмите «Сформировать»'}
                  </ToolTipInventoryTitle>
                </ToolTipInventory>
              </>
              :
                <>
                  <DutyTable
                    {...tableProps}
                    fields={fields}
                    data={tableData}
                    isDataLoading={loading}
                    exportOptions={exportOptions}
                    onFileSelect={getFile}
                    isInventoryTable
                    totalResultsInventory={totalResults}
                    withFooter
                    onUpdateData={requestInventoryTable}
                    fileStatus={fileStatus}
                  />
                </>

            }
          </TableContainer>
          </InventoryContainer>
          : 
          <>
            <TreeSidebar
              title={<Lang id={"inventory.title"}/>}
              onSelect={handleSetNode}
              hidden={false}
              selectedNode={selectedNode}
              treeId={'inventory-tree'}
            />
            <MapsContainer>
              <Header>
                <ButtonContainer>
                  <TakeInventoryButton onClick={() => setCreateMode(true)}>
                    <Lang id="inventory.takeInventory"/> 
                  </TakeInventoryButton>
                </ButtonContainer>
              </Header>
              <YandexMap
                  pinnedNode={selectedNode}
                  pins={displayElements}
                  zones={zones}
                  yandexKey={yandexKey}
                  mash={[]}
              />
            </MapsContainer>
          </>
        }
      
      </Content>
    </Main>
  )
}

export default Inventory