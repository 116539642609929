import styled from 'styled-components'
import InputMask from 'react-input-mask'
import Button from '@/components/blocks/Button'

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 5px;
  transition: color 100ms ease-in-out;
  height: 28px;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1;
  margin: 0;
  svg {
    & > .svgFill {
      fill: ${({ theme }) => theme.colors.colors.disabled};
    }
    & > .svgStroke {
      stroke: ${({ theme }) => theme.colors.backgrounds.disabled};
    }
  }
  ${({ reset }) => reset && `
    right: 65px;
    left: auto;
    cursor: pointer;
    svg {
      width: 16px
      height: 16px
    }
  `}
`
export const SearchButton = styled(Button)`
  margin-left: 10px;
  padding: 7px;
  color: ${({ disabled, theme }) => disabled ? theme.colors.backgrounds.extraLight : theme.colors.backgrounds.default};
  background: ${({ disabled, theme }) => disabled ? theme.colors.backgrounds.textFieldDisabled : theme.colors.borders.bluePrimary300};
  text-transform: capitalize;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.colors.disabled};
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 100%;
  margin-top: 5px;
  transition: color 100ms ease-in-out;
  text-align: left;
  word-break: break-word;
`

export const FieldStyled = styled(InputMask)`
  outline: none;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.lightGray};
  // background: ${({ theme }) => theme.colors.backgrounds.textField};
  border: 1px solid ${({ theme, isError }) => (isError
    ? theme.colors.messages.error
    : theme.colors.borders.textField)};
  -webkit-text-fill-color: ${({ theme }) => theme.colors.colors.textField};
  color: ${({ theme }) => theme.colors.colors.textField};
  width: 100%;
  border-radius: 4px;
  height: 28px;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0 10px;
  ${({ nested }) => nested && `
    padding: 5px;
  `}
  transition: background 100ms ease-in-out;

  ::placeholder {
    color: ${({ theme }) => theme.colors.colors.textField};
  }

  :disabled {
    border: ${({ theme }) => `1px solid ${theme.colors.backgrounds.textFieldDisabled}`};
    background: ${({ theme }) => theme.colors.backgrounds.textFieldDisabled};
    box-shadow: ${({ theme }) => theme.colors.shadows.field};
  }

  &[type=number]::-webkit-inner-spin-button, 
  &[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  @media (max-width: 910px) {
    font-size: 1.6rem;
  }
`

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TextFieldStyled = styled.div`
  width: 100%;
  display: flex;
  ${({ searchInput }) => !searchInput && `
    flex-direction: column;
  `}
  align-items: flex-start;
  position: relative;

  ${ControlsContainer} {
    height: 28px;
    position: absolute;
    right: 0px;
    top: 5px;
    svg {
      position: relative;
      top: -6px;
      & > .svgFill {
        fill: ${({ theme }) => theme.colors.colors.redisign.dark};
      }
      & > .svgStroke {
        stroke: ${({ theme }) => theme.colors.colors.redisign.dark};
      }
    }
  }

  ${FieldStyled} {
    ${({ dark, theme }) => dark && `
      // background: ${theme.colors.backgrounds.default};
      // border-color: ${theme.colors.borders.default};
    `}
  }

  ${({ fieldIcon, searchInput }) => (fieldIcon && !searchInput) && `
    ${FieldStyled} {
      padding-left: 33px;
    }
  `}

  ${({ resetButton, searchInput }) => (resetButton && !searchInput )&& `
    ${FieldStyled} {
      padding-right: 33px;
    }
  `}

  ${({ focused, filled, theme, dark }) => ((focused || filled) && !dark) && `
    ${FieldStyled} {
      background: ${theme.colors.backgrounds.textFieldActive};
      -webkit-text-fill-color: ${theme.colors.colors.textFieldActive};
      color: ${theme.colors.colors.textFieldActive};
    }
  `}

  ${({ disabled, theme, isError, dark }) => (disabled && !dark) && `
    ${FieldStyled} {
      border: 1px solid
      ${isError ? theme.colors.messages.error : theme.colors.backgrounds.textFieldDisabled};
      background: ${theme.colors.backgrounds.textFieldDisabled};
    }
  `}
`
