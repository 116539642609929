import styled from 'styled-components'

export const FieldContainer = styled.div`
  width: 100%;
  height: 28px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    width: 100%;
    transform: translate3d(0px, 55px, 0px) !important;
  }
  .react-datepicker__header {
    background: ${({ theme }) => theme.colors.borders.default};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
  }
  .react-datepicker {
    width: 300px;
    border: none;
    box-shadow: 2px 3px 15px rgba(61, 61, 61, 0.3);
    display: flex;
    font-size: 1.4rem;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__month {
    margin: 0;
    background: ${({ theme }) => theme.colors.backgrounds.default};
  }
  .react-datepicker__day {
    margin: 6px 10px;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.colors.default};

    &:hover {
      color: ${({ theme }) => theme.colors.colors.dark};
    }
  }
  div[aria-disabled="true"] {
    color: ${({ theme }) => theme.colors.colors.disabled};
  }
  .react-datepicker__day-name {
    margin: 6px 10px;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.colors.default};
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.colors.disabled};
  }
  .react-datepicker__day--keyboard-selected {
    background: ${({ theme }) => theme.colors.scheme.backgrounds.default};
    color: ${({ theme }) => theme.colors.colors.light};
  }
  .react-datepicker__calendar-icon {
    width: 28px;
    height: 28px;
  }
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0;
  }
  .react-datepicker__view-calendar-icon input {
    padding: 6px 5px 5px 10px;
    width: 100%;
  }
  .react-datepicker__close-icon {
    position: absolute;
    right: 25px;
  }
  .react-datepicker__close-icon::after {
    background-color: #3D3D3D;
  }
  input {
    height: 28px;
    font-size: 1.4rem;
    line-height: 3rem;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.backgrounds.redisign.lightGray};
    border: 1px solid ${({ theme, isError }) => (isError
        ? theme.colors.messages.error
        : theme.colors.borders.textField)};
    ${({ theme, disabled }) => disabled && `
      cursor: not-allowed;
      background: ${theme.colors.backgrounds.disabled};
    `}
  }
  input:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.borders.textField};
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
  padding: 0 10px;
`

export const IconContainer = styled.div`
  svg {
    transform: rotate(-90deg);
  }
  ${({ rotate }) => rotate && `
    svg {
      transform: rotate(90deg);
    }
  `}
`

export const SelectContainer = styled.div`
  margin: 0 10px;
  width: 100%;
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: #828D99;
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 100%;
  margin-top: 5px;
  transition: color 100ms ease-in-out;
  text-align: left;
  word-break: break-word;
`
