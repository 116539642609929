import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GeoZoneIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_97_176)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13 7C13 9.76142 9.5 14 8 14C6.5 14 3 9.76142 3 7C3 4.23858 5.23858 2 8 2C10.7614 2 13 4.23858 13 7ZM8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z" fill={fill} className="svgFill"/>
      <path d="M8 5C6.89543 5 6 5.89543 6 7H10C10 5.89543 9.10457 5 8 5Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_97_176">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fill={fill} className="svgFill" d="M9.5 6.58824C8.76667 6.58824 8.16667 7.17059 8.16667 7.88235C8.16667 8.22557 8.30714 8.55474 8.55719 8.79743C8.80724 9.04013 9.14638 9.17647 9.5 9.17647C9.85362 9.17647 10.1928 9.04013 10.4428 8.79743C10.6929 8.55474 10.8333 8.22557 10.8333 7.88235C10.8333 7.17059 10.2333 6.58824 9.5 6.58824Z"/>
    //   <path fill={fill} className="svgFill" fillRule="evenodd" clipRule="evenodd" d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM9.5 4C11.7067 4 13.5 5.74059 13.5 7.88235C13.5 10.7941 9.5 15 9.5 15C9.5 15 5.5 10.7941 5.5 7.88235C5.5 5.74059 7.29333 4 9.5 4Z"/>
    // </svg>
  )
}

GeoZoneIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

GeoZoneIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(GeoZoneIcon)
