import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LightBulbIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" className="svgFill" fill={fill} d="M14 2H8L2 8V14H3V9L5 7V8.5L4 9.5V14H5V10L6 9H10L14 5V2ZM10 7H7L9 5H12L10 7Z" />
    </svg>
  )
}

LightBulbIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}
LightBulbIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(LightBulbIcon)
