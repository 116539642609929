import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Button from '@/components/blocks/Button'
import {
  WHITE,
  GRAY,
  MINE_SHAFT,
} from '@/constants/styles/defaultTheme'
import { ACCEPT, RESET } from '@/constants/forms/globalFilterForm'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center
  margin-top: 5px;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`

export const WarepperValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Card = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: #3E3C3E;
  text-align: left;
  display: flex
  flex-direction: column;
  width: calc(100% - 20px);
  // height: 428px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  background: #FAFBFB;
  padding: 10px;
  gap: 10px;
  ${({maxWidth}) => maxWidth &&`
    max-width: 400px;
  `}
`

export const CardRow = styled.div`
  display: flex;
  gap: 40px;
  // flex: 50%;
`

export const Title = styled.div`
  width: 150px;
  min-width: 150px;
  text-wrap: wrap;
  opacity: 0.6;
  align-self: flex-start;
`

export const Value = styled.div`
  align-self: flex-start;
  word-break: break-word;
  overflow-x: hidden;

  ${({ type }) => {
    if (type === 'normalState') {
      return `
          font-size: 1.3rem;
          color: #90C737;
          border-radius: 4px;
          background-color: rgba(144, 199, 55, 0.15);
          padding: 4px;
          `
    }
    if (type === 'errorState') {
      return `
          font-size: 1.3rem;
          color: #EE2747;
          border-radius: 4px;
          background-color: rgba(238, 39, 71, 0.15);
          padding: 4px;
          `
    }
    if (type === 'undefinedState') {
      return `
          font-size: 1.6rem;
          color: #838D99;
          border-radius: 4px;
          background-color: rgba(131, 141, 153, 0.15);
          padding: 4px;
          `
    }
    if (type === 'warningState') {
      return `
          font-size: 1.6rem;
          color: #E5910C;
          border-radius: 4px;
          background-color: rgba(229, 145, 12, 0.15);
          padding: 4px;
          `
    }
    return ''
  }}
`

export const ButtonWraper = styled.div`
  width: 100%;
  margin: 15px;
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const StateWraper = styled.div`
  display: flex;
  gap: 5px;
`

export const StyledButton = styled(Button)`
  height: 38px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  ${({ usage }) => usage === ACCEPT && `
    background: linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%);
    color: ${WHITE};
  `}
  ${({ usage }) => usage === RESET && `
    background: ${GRAY};
    color: ${MINE_SHAFT};
  `}
  &:first-child {
    margin-right: 5px;
  }

  @media (max-width: 910px) {
  ${({ usage }) => usage === ACCEPT && `
    background: #1061DF;
  `}
  }
`