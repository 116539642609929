export default {
  usersOne: 'Пользователь',
  usersTwo: 'Пользователя',
  usersSome: 'Пользователей',
  users: 'Сотрудники',
  userProfile: 'Профиль пользователя',
  passportization: 'Цифровой двойник',
  installation: 'Установка и подключение',
  inventorisation: 'Инвентаризация и учет',
  alarmsOne: 'Проблема',
  alarmsTwo: 'Проблемы',
  alarmsSome: 'Проблем',
  alarms: 'Инциденты',
  individualControl: 'Индивидуальный контроль',
  groupControl: 'Групповой контроль',
  operationAndTechnicalSupport: 'Эксплуатация и тех. поддержка',
  search: 'Поиск по системе',
  installObjectsOne: 'Установка и подключение',
  installObjectsTwo: 'Инсталяции',
  installObjectsSome: 'Инсталяций',
  reportManager: 'Менеджер отчетов',
  life: 'Контроль износа и планирование ремонтов',
  scheduleManager: 'Управление расписаниями',
  objectsGroups: 'Управление группами',
  controlCenter: 'Управление инсталляциямия',
  objectsOne: 'Объект',
  objectsTwo: 'Объекта',
  objectsSome: 'Объектов',
  energyConsumption: 'Потребление энергии',
  alertManager: 'Менеджер оповещений',
  activityLog: 'Контроль действий',
  changeLog: 'Журнал изменений',
  equipmentReference: 'Справочник оборудования',
  carElectricStationsIcon: 'Зарядные автостанции',
  waterSupply: 'Водоснабжение',
  heatSupply: 'Теплоснабжение',
  smartRecycleBin: 'Контроль наполняемости мусорных баков',
  ecoStations: 'Экостанции',
  settings: 'Параметры системы',
  statisticsOnObjectsControl: 'Статистика по объектам контроля',
  analyticExpenses: 'Аналитика расходов',
  analyticConsumption: 'Аналитика\nпотребл. ресурсов',
  eventsCount: 'Событий',
  eventsNewCount: 'Новых',
  mountOne: 'Инсталляция',
  mountTwo: 'Инсталляции',
  mountSome: 'Инсталляций',
  projectOne: 'Проект',
  projectTwo: 'Проекта',
  projectSome: 'Проектов',
  intergationWithService: 'Интеграция с сервисом:',
  connect: 'Подключиться',
  disconnect: 'Отключиться',
  chargingStation: 'Зарядные автостанции',
  architectureIllumination: 'Архитектурное освещение',
  garbageCollection: 'Контроль наполняемости мусорных баков',
  roadInfrastructure: 'Мониторинг дорожной\nинфраструктуры',
  trafficLight: 'Управление светофорами',
  infoFrame: 'Размещение инфотабло',
  handicappedPlaces: 'Контроль занятости\nмест для инвалидов',
  support: 'Сервис Деск «Мой город»',
  widgetsDashboard: 'Дашборд',
  widgetsDashboardGov: 'Дашборд 2',
  apiUrl: 'API URL',
  port: 'Port',
  login: 'Логин',
  password: 'Пароль',
  myDescSettingsTitle: 'Для использования приложения\nего необходимо настроить',
  setup: 'Настроить виджет',
  receiveMessages: 'Получено обращений',
  execution: 'Исполнено',
  clientID: 'ClientID*',
  secretID: 'SecretID*',
  light: 'Наружное освещение',
  tyumenOurHome: 'Портал Тюмень - НАШ ДОМ',
  name: 'Название*',
  fileWithSettings: 'Файл с настройками*',
  url: 'URL*',
  day: 'д.',
  hour: 'ч.',
  minutes: 'мин.',
  sec: 'сек.',
  solving: 'в процессе решения',
  failOrNoProblem: 'отказ или проблем не обнаружено',
  resolved: 'успешно решено',
  decisionMade: 'Решение принято за',
  cityProcess: 'Городской процесс',
  doWidgetSettings: 'Настроить виджет',
  doWidgetSettingsMessage: 'Для использования виджета его необходимо настроить',
  widgetSettings: 'Настройки виджета',
  updateFrequency: 'Частота обновления',
  objectStatus: 'Статус объекта',
  currentMode: 'Текущий режим',
  controllerAvailability: 'Доступность контроллера',
  gsmLevel: 'Уровень GSM сети',
  gsmType: 'Тип GSM сети',
  hasConnection: 'Состояние подключения',
  online: 'на связи',
  offline: 'нет связи',
  alarmState: 'Состояние аварии',
  absent: 'отсутствует',
  notActivelyAcknowledged: 'активно не сквитировано',
  notActivelyNotAcknowledged: 'не активно не сквитировано',
  activelyAcknowledged: 'активно сквитировано',
  phaseState: 'Состояние фазы',
  itActive: 'активно',
  itNotActive: 'не активно',
  heActive: 'активен',
  heNotActive: 'не активнен',
  counterStatus: 'Состояние счетчика',
  fireSensorStatus: 'Состояние датчика пожара',
  norm: 'норма',
  sensorBreak: 'обрыв датчика',
  heatDetector: 'сработал тепловой извещатель',
  smokeDetector: 'сработал дымовой извещатель',
  shortCircuit: 'короткое замыкание',
  contactorIsOn: 'Контактор выкл./вкл.',
  on: 'включен',
  off: 'выключен',
  itOn: 'включено',
  itOff: 'отключено',
  isActiveCommandOn: 'Отправлена команда на включение контактора',
  commandOn: 'команда активна',
  commandOff: 'команда неактивна',
  mode: 'Режим управления',
  local: 'местный',
  remote: 'дистанционный',
  controlType: 'Тип управления',
  manual: 'ручной',
  autonomic: 'автономный',
  cascade: 'каскадный',
  contactorSwitchTime: 'Дата и время планового включения контактора',
  willContactorSwitchOn: 'Будет произведено плановое включение контактора',
  yes: 'да',
  no: 'нет',
  circuitState: 'Состояние вставки/автомата включения',
  undefined: 'не определено',
  fault: 'авария',
  checkPointState: 'Состояние контрольной точки',
  currentSensorValue: 'Значение датчика тока',
  dmxId: 'Идентификатор DMX-модуля',
  isActive: 'Активен ли сценарий',
  activationTime: 'Время активации сценария',
  scenarioNumber: 'Номер текущего сценария',
  deferredScenarioNumber: 'Номер отложенного сценария',
  dmxErrorCode: 'Код неисправности модуля DMX',
  pressureUnits: 'мм рт. ст.',
  pressureInPipe: 'Давление в подающем трубопроводе',
  pressureInReversePipe: 'Давление в обратном трубопроводе',
  massFlowUnits: 'т/ч',
  illuminanceLevel: 'Уровень диммирования',
  activeEnergy: 'Энергия активная',
  reactiveEnergy: 'Энергия реактивная',
  voltage: 'Напряжение',
  voltageUnits: 'В',
  current: 'Ток',
  currentUnits: 'А',
  power: 'Мощность',
  powerUnits: 'Вт',
  reactivePowerUnits: 'ВАр',
  frequency: 'Частота',
  frequencyUnits: 'Гц',
  ua: 'Фазное напряжение UA',
  ub: 'Фазное напряжение UB',
  uc: 'Фазное напряжение UC',
  ia: 'Ток по фазе IA',
  ib: 'Ток по фазе IB',
  ic: 'Ток по фазе IC',
  ca: 'Коэффициент мощности фазы CA',
  cb: 'Коэффициент мощности фазы CB',
  cc: 'Коэффициент мощности фазы CC',
  qa: 'Реактивная мощность по фазе QA',
  qb: 'Реактивная мощность по фазе QB',
  qc: 'Реактивная мощность по фазе QC',
  pa: 'Активная мощность по фазе PA',
  pb: 'Активная мощность по фазе PB',
  pc: 'Активная мощность по фазе PC',
  electricalResistance: 'Электрическое сопротивление',
  resistanceUnits: 'Ом',
  counterCommunication: 'Связь со счетчиком',
  connectionStatus: 'Состояние связи',
  networkLevel: 'Уровень сети',
  illumination: 'Освещенность',
  illuminationUnits: 'Лк',
  temperature: 'Температура',
  readingDate: 'Дата и время показаний',
  lightVoltage: 'Напряжение на светильнике',
  lightCurrent: 'Сила тока на светильнике',
  lightPower: 'Мощность светильника',
  lightReactivePower: 'Реактивная мощность светильника',
  lightFullPower: 'Полная мощность светильника',
  lightPowerCoefficient: 'Коэффициент мощности светильника',
  lightFrequency: 'Частота на светильнике',
  energy: 'Накопленная энергия',
  energyUnits: 'Вт*ч',
  energyUnitsKilo: 'кВт*ч',
  voltagePeaks: 'Амплитудные значения напряжения',
  currentPeaks: 'Амплитудные значения силы тока',
  lastDevastation: 'Последнее опустошение',
  wasteType: 'Вид отходов',
  category: 'Категория',
  lastCleaning: 'Последняя уборка',
  nextCleaning: 'Следующая уборка',
  lastOn: 'Последнее включение',
  lastOff: 'Последнее выключение',
  availableModes: 'Доступные режимы',
  semiautomatic: 'Полуавтоматический',
  automatic: 'Автоматический',
  lastUpdate: 'Последнее обновление',
  locationCategory: 'Категория местоположения',
  sidewalk: 'тротуар',
  powerConsumption: 'Потребляемая мощность',
  lightPowerConsumption: 'Потребляемая мощность лампы',
  unitPressure: 'кгc/см',
  m: 'м',
  h: '/ч',
  massFlow: 'Массовый расход',
  volumeFlow: 'Объемный расход',
  averageTimeTaken: 'Средн. время исправления',
  critical: 'Критические',
  fixed: 'Исправлены',
  alarmsTotal: 'Всего алармов',
  callsTotal: 'Всего обращений',
  averageDecisionExecutionTime: 'Средн. время взятия в работу',
  averageDecisionTime: 'Средн. время принятия решения',
  inWork: 'В работе',
  decisionWasMade: 'Принято решение',
  decisionsWasMade: 'Принято решений',
  roadRepairingCount: 'Ремонт дорог',
  transportStationCount: 'Остановки общ. транспорта',
  notAvailable1: 'Прорыв теплотрасс',
  notAvailable2: 'Подтопления',
  planned: 'Запланировано',
  problemsSolved: 'Успешно решено',
  refuse: 'Мотивированный отказ',
  problemNotFound: 'Проблема не обнаружена',
  pprDescription: 'Контроль проведения ППР',
  activitiLogDescription: 'Журнал действий',
  searchDescription: 'Настраиваемый поиск объектов в системе',
  heatSupplyDescription: 'Подключение к системе объектов теплоснабжения',
  waterSupplyDescription: 'Подключение к системе объектов водоснабжения',
  chargingStationDescription: 'Подключение к системе зарядные автостанции',
  architectureIlluminationDescription: 'Подключение к системе объектов архитектурно-художественной подсветки',
  garbageCollectionDescription: 'Подключение к системе объектов раздельного сбора мусора',
  roadInfrastructureDescription: 'Подключение к системе объектов мониторинга дорожной инфраструктуры',
  trafficLightDescription: 'Подключение к системе объектов управления светофорами',
  infoFrameDescription: 'Подключение к системе размещения инфотабло',
  handicappedPlacesDescription: 'Подключение к системе контроля занятости мест для инвалидов',
  widgetsDashboardDescription: 'Объединенный центр сбора и контроля статистических данных из всех подключенных к системе источников данных',
  widgetsDashboardGovDescription: 'Объединенный центр сбора и контроля статистических данных из всех подключенных к системе источников данных',
  alarmAnalytics: 'Аналитика неисправностей',
  alarmAnalyticsDescription: 'Модуль сбора и анализа статистики неисправностей оборудования в выбранной геозоне',
  tyumenOurHomeDescription: 'Просмотр обращений граждан по различным проблемам городской среды для получения актуальной информации о проблемах в городе',
  roadRepairing: 'Ремонт и реконструкция дорог',
  publicTransport: 'Остановки общественного транспорта',
  supportDescription: 'Виджет для просмотра истории обращений и формирования новых заявок для устранения аварийных ситуаций',
  objectsGroupsDescription: 'Приложение для группировки объектов с последующей возможностью совершения выбранных действий над сформированной группой',
}
