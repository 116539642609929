import styled from 'styled-components'

export const Container = styled.div`
  width: 310px;
  min-width: 310px;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
export const Header = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid  ${({ theme }) => theme.colors.borders.default};
  button {
    height: 28px;
    display: flex;
    align-items: center;
  }
  div:not(:first-child) {
    justify-self: flex-end;
    margin-left: 10px;
  }
  div:first-child {
    flex: 1;
  }
`

export const StyledButton = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  color: ${({ theme }) => theme.colors.white};
  svg,
  .svgFill,
  .svgStroke {
    height: 18px;
    fill: ${({ theme }) => theme.colors.colors.redisign.default};
  }
  background: ${({ type, theme }) => {
    if (type === 'green') return theme.colors.messages.fix
    if (type === 'red') return theme.colors.messages.critical
    return 'transparent'
  }};
  cursor: pointer;
  ${({ theme, border }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
`

export const HeaderTitle = styled.div`
  width: 30%;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: left;
  white-space: wrap;
`


export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${({ ghostMode }) => ghostMode && `
    opacity: 0.3;
    cursor: not-allowed;
    position: relative;
    top: 0px;
    left: 0px;
    ::after {
      position: absolute;
      width: 100%;
      height: calc(100vh - 96px);
      content: ' ';
      top: 0;
      left: 0;
    }
  `}
`

export const TitleContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: left;
  padding: 0px 10px;
`

export const CkeckBoxsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
`
export const CkeckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TabContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const OptionTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  padding-left: 10px;
`


export const Tab = styled.div`
  width: 50%;
  height: 100%;
  color: ${({ theme }) => theme.colors.colors.default};
  background: ${({ theme }) => theme.colors.backgrounds.default};
  ${({ isActive, theme }) => isActive && `
    color: ${theme.colors.backgrounds.default};
    background: ${theme.colors.colors.tab};
  `}
  text-transform: capitalize;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme, border }) => border && `
    border-${border}: 1px solid ${theme.colors.borders.default};
  `}
`
export const PassportImageContainer = styled.div`
  width: 100%;
  height: auto;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.borders.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const PassportImage = styled.img.attrs(({ base64Url, url }) => ({
  src: base64Url || url,
}))`
  height: 100%;
  max-width: 100%;
`
export const InputAndLabelContainer = styled.div`
  padding: 5px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  && > div {
    text-align: left;
  }
  input {
    width: 100%;
    height: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}

${({ select, theme }) => select && `
  border-bottom: 1px solid ${theme.colors.borders.default};
  padding-bottom: 10px;
`}
`


export const SecondaryTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: left;
  ${({ status, theme }) => status && `
    font-weight: bold;
    color: ${theme.colors.scheme.backgrounds.default};
    padding-left: 10px;
    text-transform: uppercase;
  `}
  ${({ fw }) => fw && `
    width: 100%;
  `}
  ${({ color }) => color && `
    color: ${color};
  `}
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: center;
`

export const ElementContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  ${({ bs, theme }) => bs && `
    button {
      box-shadow: ${theme.colors.shadows.pin};
    }
  `}
  ${SecondaryTitle} {
    padding-bottom: 10px;
  }
  ${({ theme, bb }) => bb && `
    border-bottom: 1px solid ${theme.colors.borders.default};
  `}
  ${({ bw }) => bw && `
    button {
      width: ${bw}%;
    }
  `}
  ${({ bh }) => bh && `
    button {
      height: ${bh}px;
    }
  `}
`