import React, { useEffect, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import Button from '@/components/blocks/Button'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import VersionControl from '@/components/blocks/VersionControl'
import Popup from '@/components/blocks/Popup'
import {
  UserWrapper,
  UserImage,
  UserName,
  UserRole,
  NumberWrapper,
  Number,
  StyledSidebar,
  VersionWrapper,
  NumberVersion,
  ChengeLogWrapper,
} from './styles'

const UserSidebar = ({
  isOpen, onClose, onLogout, user, applicationsVersion,
}) => {
  const userIcon = require('@/assets/images/user.png')
  const ref = React.createRef()
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setPopupOpen(false)
    }
  }, [isOpen])

  useOutsideClickHandler({
    ref,
    callback: onClose,
    opened: isOpen,
    exception: true,
  })

  return (
    <StyledSidebar ref={ref} opened={isOpen} onClose={onClose} over position="right">
      <Popup 
          opened={popupOpen} 
          versionPopup
          popupContent={<VersionControl setPopupOpen={setPopupOpen} data={applicationsVersion}/>} 
        >
      </Popup>
      <UserWrapper>
        <UserImage>
          <img alt="User" src={userIcon} />
        </UserImage>
        <UserName title={`${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`}>
          {user.firstName || ''}
          {user.lastName ? ` ${user.lastName}` : ''}
        </UserName>
        <UserRole spaced={!user.group}>
          <Lang id={`userProfile.roles.${user.role}`} />
        </UserRole>
        {user && user.group && (
          <UserRole spaced={!user.jobTitle} dark>
            <Lang id="userProfile.group" />
            :
            {' '}
            {user.group.name}
          </UserRole>
        )}
        {user && user.jobTitle && (
          <UserRole spaced dark>
            <Lang id="userProfile.jobTitle" />:
            {' '}
            {user.jobTitle}
          </UserRole>
        )}
        <Button onClick={onLogout} styleType={'info'}>
          <Lang id="userProfile.signOutButton" />
        </Button>
        <VersionWrapper>
        <NumberVersion>
          {`Версия ${applicationsVersion[0]?.name}`}
        </NumberVersion>
        <ChengeLogWrapper onClick={() => setPopupOpen(true)}>
          <Lang id="constants.changeLog" />
        </ChengeLogWrapper>
      </VersionWrapper>
      </UserWrapper>
      <NumberWrapper>
        <Number>
          <Lang id="userProfile.number" />
        </Number>
        <p><Lang id="userProfile.numberDescription" /></p>
      </NumberWrapper>
    </StyledSidebar>
  )
}

UserSidebar.propTypes = {
  isOpen: pt.bool.isRequired,
  onClose: pt.func.isRequired,
  onLogout: pt.func,
  user: pt.shape({
    tenantId: pt.number,
    id: pt.number,
    firstName: pt.string,
    lastName: pt.string,
    role: pt.string,
    jobTitle: pt.string,
    group: pt.shape({
      name: pt.string,
    }),
  }),
}
UserSidebar.defaultProps = {
  onLogout: noop,
  applicationsVersion: [],
  user: {
    firstName: '',
    lastName: '',
    jobTitle: '',
    group: {},
  },
}

export default memoWithName(UserSidebar)
