export default {
    titles: {
        main: 'Управление расписаниями',
        search: 'Поиск расписания',
        create: 'Добавление\nрасписания',
        edit: 'Редактирование\nрасписания',
        add: 'Добавить расписание',
        schedule: 'Расписание',
        calendar: 'Календарь',
        scheduleFailureLog: 'Журнал сбоев в работе расписания',
        userActivityLog: 'Журнал действий пользователей',
        emptyScheduleText: 'Выберите расписание из списка или создайте новое',
        errorStatus: 'Статус ошибки',
        action: 'Действие',
    },
    tooltip: {
        edit: 'Редактировать',
        delete: 'Удалить',
        deleteSelected: 'Удалить выбранные',
        download: 'Скачать файл расписания',
        chart: 'Показать на графике',
        addTask: 'Добавить задание',
    },
    dateInterval: {
        set: 'Построить',
        reset: 'Сбросить',
    },
    form: {
        name: 'Название',
        temperature: 'Температура воздуха (°C)',
        pressure: 'Атмосферное давление (мм рт. ст.)',
        note: 'Описание / комментарий',
        newSchedule: 'Новое расписание',
        scheduleFile: 'Файл расписания',
        scheduleName: 'Название расписания*',
        timezone: 'Часовой пояс',
        comment: 'Описание / комментарий',
        select: 'Выбрать',
        delete: 'Удалить расписание',
        addButton: 'Добавить',
        tasks: 'Задания',
        task: 'Задание',
        taskName: 'Название задания',
        deviceType: 'Тип оборудования',
        objectsGroup: 'Группа объектов',
        phase: 'Фаза',
        phase1: 'Фаза 1 ',
        phase2: 'Фаза 2 ',
        phase3: 'Фаза 3 ',
        repeat: 'Повтор',
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
        hours: 'Ч:',
        minutes: 'М:',
        percent: 'Ур.(%):',
        condition: 'Сост.:',
        mode: 'Режим',
        on: 'Вкл',
        off: 'Выкл',
        actions: 'Время и действие',
        workingHours: 'Суммарные часы работы в задании',
        dailyWorkingHours: 'Суммарные часы работы за сутки',
        scheduleWorkingHours: 'Суммарные часы работы в расписании',
        addAction: 'Добавить действие',
        saveTask: 'Сохранить задание',
        rm: 'РМ',
        shuno: 'ШУНО',
        commentText: 'Текст комментария...',
    },
    popup: {
        yes: 'Да',
        no: 'Нет',
        accept: 'Принять',
        save: 'Сохранить',
        cancel: 'Отмена',
        attention: 'Внимание!',
        executeImpossible: 'Невозможно выполнить!',
        deleteTaskMessage: 'Вы действительно хотите удалить задания?',
        cancelCreateScheduleMessage: 'Вы действительно хотите отменить создание расписания?',
        cancelEditScheduleMessage: 'Вы действительно хотите отменить внесенные изменения в расписание?',
        createScheduleErrorMessage: 'Для создания нового расписания необходимо выбрать геозону',
        createScheduleValidationErrorMessage: 'Для создания расписания необходимо установить все обязательные параметры',
        editScheduleValidationErrorMessage: 'Для сохранения изменений в расписании необходимо установить все обязательные параметры',
        createSchedule: 'Создать новое расписание?',
        createScheduleMessage: 'Введите комментарий-обоснование для создания нового расписания',
        editSchedule: 'Сохранить изменения в расписании?',
        editScheduleMessage: 'Введите комментарий-обоснование для внесения изменений в расписание',
        deleteSchedule: 'Удалить расписание?',
        deleteScheduleMessage: 'Введите комментарий-обоснование для удаления расписания',
    },
    time: 'Время',
    level: 'Уровень',
    state: 'Состояние',
    dimmingLevel: 'Уровень диммирования',
    shunoWork: 'График работы ШУНО',
    graphTitle: 'График за сутки',
    firstGraphTitle: 'График за первые сутки',
    secondGraphTitle: 'График за вторые и последующие сутки',
}
