import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`
export const Container = styled.div`
  width: 320px;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  svg {
    cursor: pointer;
  }
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-right: 5px;
  }

`
export const PrimaryTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: left;
  ${({ flex }) => flex && `
    flex: 1;
    margin-right: 50px;
    min-width: 70px;
  `}
  ${({ value }) => value && `
    font-size: 1.6rem;
    opacity: 0.6;
  `}
  color: #3E3C3E;
   ${({ textCenter }) => textCenter && `
    text-align: center;
  `}
`
export const SecondaryTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 3rem;
  color: #3E3C3E;
`

export const MainTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4rem;
  padding: 10px 0px;
  color: #3E3C3E;
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
`

export const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;
  .svgFill,
  .svgStroke {
    height: 18px;
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  ${PrimaryTitle}:first-child {
    text-align: left;
    margin-right: 15px;
  }
`
