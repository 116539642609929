import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'
import { Link } from 'react-router-dom'


export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        border-top: 1px solid ${theme.colors.borders.redisign.default};
        background: ${theme.colors.backgrounds.redisign.topDownGradient};
      `
    }
    return `
      border-top: 1px solid ${theme.colors.borders.default};
      background: ${theme.colors.backgrounds.header};
    `
  }}
  color: ${({ theme }) => theme.colors.colors.default};
  z-index: 10;
`

export const Title = styled.h3`
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  ${({ isBigChildren }) => isBigChildren && `
    overflow: hidden;
  `}
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        font-size: 1.2rem;
        font-weight: 500;
      `
    }
    return `
      font-weight: ${theme.fontWeights.bold};
      font-size: 1.4rem;
      line-height: 2.4rem;
    `
  }}
  ${({isActive}) => isActive && `
    color: #1061DF;
  `}
`
export const ContainerIcon = styled.div`
  max-width: 76px;
  height: 48px
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`
export const LinkWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around
  gap: 10px;
  min-height: 76px;
  width: 430px;
`

export const IconWreper = styled.div`
  min-width: 24px;
  min-height: 24px;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ to }) => to === '#' && `
    opacity: 0.2;
    // pointer-events: none;
  `}
`
