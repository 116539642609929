import { put, takeLatest, } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_CHECKED_OBJECTS_FILE_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_CHECKED_OBJECTS_FILE } from '@/store/actions/objectsGroups'
import getObjectsGroupCreateRequestTree from '@/helpers/getObjectsGroupCreateRequestTree';
import uniqBy from 'lodash/uniqBy';
import getTimeZoneOffset from '@/helpers/getTimeZoneOffset';
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob';
import moment from 'moment';
import { successGetCheckedObjectsFile } from '@/store/actions/objectsGroups/getCheckedObjectsFile';

function* getCheckedObjectsFileSaga({ payload }) {
  const toast = createNotifications()

  const errorNotify = () => {
    toast({
      title: 'Ошибка операции!',
      description: 'Не выбраны объекты для выгрузки.',
      type: 'error',
    })
  }

  try {
    if (!payload.objects.length) {
      yield put(successGetCheckedObjectsFile())
      errorNotify()
      return
    }

    const objectsTree = getObjectsGroupCreateRequestTree(payload.objects)
    const objectIds = uniqBy(objectsTree, 'id')

    if (!objectIds.length) {
      yield put(successGetCheckedObjectsFile())
      errorNotify()
      return
    }

    const { filters } = payload
    const objectFilter = Object.values(filters).some(item => item.length > 0)
      ? {
        balanceSheetHolders: filters.networkCompany,
        installationTypes: filters.objectType,
        lampTypes: filters.lightFixtureType,
        locationTypes: filters.pedestrianCrossingSign,
        registerNumbers: filters.registryNumber,
        streets: filters.street,
        utilityPoleTypes: filters.utilityPoleType,
        vols: filters.vols,
        parentId: payload.objects[0].parentId,
      }
      : null

    const body = {
      objects: objectIds,
      timeZoneOffset: getTimeZoneOffset(),
      objectFilter,
    }

    const response = yield request({
      url: GET_CHECKED_OBJECTS_FILE_URL,
      method: 'post',
      body,
      options: { responseType: 'blob' },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `Список объектов за ${moment(new Date()).format('DD.MM.YYYY')}.xlsx`,
    })

    yield put(successGetCheckedObjectsFile())
  } catch (error) {
    console.log(error)
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить список объектов.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_CHECKED_OBJECTS_FILE.REQUEST, getCheckedObjectsFileSaga)
}
