import styled from 'styled-components'
import LabelWithIcon from '@/components/blocks/LabelWithIcon/styles'
import {
  ValueIcon,
  ValueSelectStyled
} from '@/components/controls/ValueSelect/styles'
import { IconContainer } from '@/forms/ScheduleManager/CreateEditScheduleForm/styles'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  min-width: 310px;
  max-width: 310px;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const ActionsContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  width: 100%;
  max-width: 400px;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const Header = styled.div`
  padding: 15px 10px 15px 20px;
  width: 100%;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  && > div:last-child {
    justify-self: flex-end;
  }
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`

export const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 3rem;
`

export const FieldsContainer = styled.div` 
  width: 100%;
  padding: 10px;
  ${({ ghostMode }) => ghostMode && `
    opacity: 0.3;
    cursor: not-allowed;
    position: relative;
    top: 0;
    left: 0;
    ::after {
      position: absolute;
      width: 100%;
      height: calc(100vh - 96px);
      content: ' ';
      top: 0;
      left: 0;
    }
  `}
`

export const AddTaskButton = styled.div`
   display: flex;
   align-items: center;
`
export const DeleteTaskButton = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InputAndLabelContainer = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  && > div {
    text-align: left;
  }
  input {
    width: 100%;
    height: 28px;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
  & > span {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: green;
  }
  ${LabelWithIcon} {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.colors.colors.redisign.default};
    margin-bottom: 5px;
  }
`

export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  input {
    width: 100%;
    margin-top: 5px;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
  padding: 5px 0;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`

export const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px 10px 0 10px
`

export const TaskContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0 10px;
  margin-bottom: 5px;
  height: 28px;
  font-weight: 500;
  svg {
    position: absolute;
    right: 5px;
    width: 16px;
    rotate: 180deg;
  }
`

export const CustomFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  margin: 5px 0;
  ${IconContainer} {
  height: 28px;
}
`

export const ActionsWrapper = styled.div`
  width: 100%;
  margin-top: 5px;
`

export const AddActionButton = styled.div`
  height: 28px;
  width: 100%;
  padding: 10px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.secondary};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 15px;
  svg {
    padding-left: 5px;
  }
`
export const SaveTaskButton = styled.button`
  height: 28px;
  width: 100%;
  padding: 10px;
  color: #3D3D3D;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.messages.fix};
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-family: Inter, serif;
  font-style: normal;
  ${({ theme, disabled }) => disabled && `
    cursor: not-allowed;
    background: ${theme.colors.backgrounds.disabled};
  `}
  svg {
    padding-left: 5px;
  }
`

export const ActionBlock = styled.div`
  display: flex;
  align-items: normal;
  ${LabelWithIcon} {
    width: 100%;
    margin-right: 5px;
    margin-bottom: 0;
  }
  input {
    width: 80px;
  }
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.4rem;
  line-height: 1.6rem;
  ${LabelWithIcon} {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;
    margin: 15px 0 5px 0;
  }
`

export const ChartTitle = styled.div`
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-style: normal;
`

export const ChartWrapper = styled.div`
  margin-bottom: 25px;
`

export const ChartContainer = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 35px 15px 15px 15px;
  box-shadow: ${({ theme }) => theme.colors.shadows.graph};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  .canvasjs-chart-credit{
    display: none;
  }
`

export const PercentContainer = styled.div`
  ${ValueSelectStyled} {
    min-height: unset;
  }
  ${ValueIcon} {
    top: -25px;
  }
`

export const IconTooltip = styled.div`
  cursor: pointer;
`