import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const NoSelectedPinIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2151_5211)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2H12V3H11V6.38197L12.309 9H13V10H8.5V13L8 14L7.5 13V10H3V9H3.69098L5 6.38197V3H4V2Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_2151_5211">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <g clipPath="url(#clip0)">
    //     <path d="M6.86956 6L13.1742 12H10.4722V15.4286L9.57155 18L8.67089 15.4286V12H5.06823C4.82936 12 4.60027 11.9097 4.43136 11.7489C4.26246 11.5882 4.16757 11.3702 4.16757 11.1429V9.78343C4.16762 9.55612 4.26254 9.33814 4.43146 9.17743L6.86956 6.85714V6ZM13.1742 0C13.4131 0 13.6422 0.0903058 13.8111 0.251051C13.98 0.411797 14.0749 0.629814 14.0749 0.857143V1.18457C14.0748 1.34371 14.0282 1.49968 13.9402 1.63502C13.8522 1.77036 13.7264 1.87972 13.5768 1.95086L12.2735 2.57143V6.85714L14.7116 9.17743C14.8806 9.33814 14.9755 9.55612 14.9755 9.78343V11.1429C14.9757 11.2555 14.9524 11.3671 14.9071 11.4711L6.86956 3.82286V2.57143L5.5663 1.95086C5.41672 1.87972 5.29089 1.77036 5.20293 1.63502C5.11496 1.49968 5.06832 1.34371 5.06823 1.18457V0.857143C5.06823 0.629814 5.16312 0.411797 5.33203 0.251051C5.50094 0.0903058 5.73002 0 5.9689 0H13.1742Z" fill={fill} className="svgFill" />
    //     <path d="M0.236164 0.775714C0.0849151 0.920287 0 1.11599 0 1.32C0 1.52401 0.0849151 1.71971 0.236164 1.86429L16.6193 17.4557C16.7709 17.6001 16.9767 17.6812 17.1912 17.6812C17.4057 17.6812 17.6114 17.6001 17.7631 17.4557C17.9148 17.3114 18 17.1156 18 16.9114C18 16.7073 17.9148 16.5115 17.7631 16.3671L1.38001 0.775714C1.3049 0.704235 1.21574 0.647534 1.11761 0.60885C1.01948 0.570165 0.914303 0.550255 0.808086 0.550255C0.70187 0.550255 0.596693 0.570165 0.498562 0.60885C0.400432 0.647534 0.311268 0.704235 0.236164 0.775714Z" fill={fill} className="svgFill" />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0">
    //       <path d="M0 0H18V18H0V0Z" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  )
}

NoSelectedPinIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

NoSelectedPinIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(NoSelectedPinIcon)
