import React, { useCallback } from 'react'
import pt from 'prop-types'
import { Link } from 'react-router-dom'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import HeaderIcons from '@/components/icons/header'
import PortalTooltip from '@/components/blocks/PortalTooltip'

import {
  ContainerLink,
  WidgetHeaderIcon,
  Container,
  MainTitle,
  TitleContainer,
  IconWrapper,
} from './styles'

const CustomApplicationWidget = ({
  id,
  widgetType,
  type,
  title,
  icon: Icon,
  path,
  onOpenSettings,
  params,
}) => {
  const LinkComponent = path ? Link : React.Fragment
  const linkProps = path
    ? {
      to: {
        pathname: path,
        search: params || null,
      },
    }
    : {}
  const isBig = type === 'big'

  const handleOpenSettings = useCallback(() => {
    onOpenSettings(id, type, widgetType)
  }, [onOpenSettings, id, type, widgetType])

  const color = (icon) => {
    switch (type) {
      case 'PPR':
        return `rgba(215, 81, 99, ${icon ? 1 : 0.2})`
      case 'OBJECTS_GROUPS':
        return `rgba(71, 128, 243, ${icon ? 1 : 0.2})`
      case 'ActivityLogIcon':
        return `rgba(196, 71, 255, ${icon ? 1 : 0.2})`
      case 'PassportizationIcon':
        return `rgba(76, 153, 84, ${icon ? 1 : 0.2})`
      case 'SEARCH':
        return `rgba(71, 128, 243, ${icon ? 1 : 0.2})`
      case 'LifeIcon':
        return `rgba(196, 71, 255, ${icon ? 1 : 0.2})`
      default:
        return `rgba(236, 143, 76, ${icon ? 1 : 0.2})`
  }}

  return (
    <ContainerLink isBig={isBig} disabled={path} background={color()}>
      <LinkComponent {...linkProps}>
      <TitleContainer>
        <IconWrapper background={color()} >
          <Icon />
        </IconWrapper>
          {title && <MainTitle invert>{title}</MainTitle>}
        </TitleContainer>
      </LinkComponent>
      <PortalTooltip
        title={(<Lang id="tooltip.settings" />)}
        renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
          <WidgetHeaderIcon
            onClick={handleOpenSettings}
            ref={wrapperRef}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
          >
            <HeaderIcons.SettingsIcon />
          </WidgetHeaderIcon>
        )}
      />
    </ContainerLink>
  )
}

CustomApplicationWidget.defaultProps = {
  type: 'small',
  id: null,
  widgetType: null,
  path: null,
  count: null,
  notification: null,
  iconTitle: null,
  valueList: [],
  descriptionFields: [],
  onOpenSettings: noop,
}

CustomApplicationWidget.propTypes = {
  id: pt.string,
  widgetType: pt.string,
  type: pt.string,
  iconTitle: pt.string,
  path: pt.string,
  count: pt.number,
  notification: pt.string,
  icon: pt.string.isRequired,
  valueList: pt.arrayOf(pt.object),
  title: pt.oneOfType([pt.string, pt.element, pt.node]).isRequired,
  descriptionFields: pt.arrayOf(pt.string),
  onOpenSettings: pt.func,
}

export default memoWithName(CustomApplicationWidget)
