import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${({ pl }) => pl && `
    padding-left: ${pl}px;
  `}
  ${({ align }) => align && `
    justify-content: ${align};
    `}
  ${({ align }) => {
    if (align === 'center') {
      return `
        justify-content: center;
        padding-left: 0px;
      `
    }
  }}
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  margin-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
`

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Mark = styled.div`
  width: 10px;
  height: 15px;
  ${({ color }) => color && `
    background: ${color};
  `}
`

export const ItemContainer = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  ${Mark} {
    margin-right: 5px;
  }
`
export const Error = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.messages.error};
`
