import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 48px;
`
export const HeaderTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
`

export const HeaderIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
`
