export default {
  username: 'Email',
  password: 'Пароль',
  signInButton: 'Авторизация',
  forgotPasswordButton: 'Не помню пароль',
  forgotPasswordText: 'Для восстановления пароля,\nвведите Ваш email',
  forgotPasswordReturn: 'Назад к авторизации',
  goToAuthorization: 'Перейти к авторизации',
  restore: 'восстановить пароль',
  submittedText: 'Инструкции по\nвосстановлению пароля\nбыли отправлены на\nуказанный email',
  errorText: 'Отказано в доступе!\nОбратитесь к Администратору\nВашей рабочей группы или повоторите попытку позже.',
}
