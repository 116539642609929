import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'


const PprIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_73_1353)">
      <path d="M9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5V6H37V5C37 4.44772 37.4477 4 38 4C38.5523 4 39 4.44772 39 5V6C39 6.04199 38.9987 6.08368 38.9962 6.12503C40.6479 6.54841 41.8877 8.00007 41.9928 9.75745C41.4629 9.28624 40.7649 9 40 9H39.7324C39.3866 8.4022 38.7403 8 38 8H10C9.25972 8 8.61337 8.4022 8.26756 9H8C7.23513 9 6.53712 9.28624 6.00723 9.75744C6.11227 8.00143 7.35016 6.55066 9 6.12602V5Z" fill={fill} className="svgFill"/>
      <path d="M8 15C5.79086 15 4 16.7909 4 19V40C4 42.2091 5.79086 44 8 44H36.9808L33.5167 42H8C6.89543 42 6 41.1046 6 40V19C6 17.8954 6.89543 17 8 17H40C41.1046 17 42 17.8954 42 19V35.3509L42.2583 35.5C42.9936 35.9245 43.5802 36.5075 44 37.181V19C44 16.7909 42.2091 15 40 15H8Z" fill={fill} className="svgFill"/>
      <path d="M9 12H39C40.1046 12 41 12.8954 41 14C41.7684 14 42.4692 14.2889 43 14.7639V14C43 11.7909 41.2091 10 39 10H9C6.79086 10 5 11.7909 5 14V14.7639C5.53076 14.2889 6.23165 14 7 14C7 12.8954 7.89543 12 9 12Z" fill={fill} className="svgFill"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.367 25.0987C26.1221 23.8025 23.4585 23.8923 21.3668 25.0977C21.0571 25.2761 20.8662 25.6063 20.866 25.9637C20.8659 26.3211 21.0565 26.6514 21.366 26.8301L24.135 28.4288C25.0915 28.9811 25.4193 30.2042 24.867 31.1608C24.3147 32.1174 23.0915 32.4452 22.135 31.8929L19.366 30.2942C19.0565 30.1155 18.6751 30.1156 18.3657 30.2944C18.0562 30.4733 17.8657 30.8037 17.866 31.1611C17.868 33.5752 19.1221 35.9269 21.367 37.223C23.7864 38.6199 26.6911 38.4077 28.8456 36.9219L38.7846 42.6603C40.2195 43.4887 42.0543 42.9971 42.8827 41.5622C43.7111 40.1273 43.2195 38.2925 41.7846 37.4641L31.8456 31.7258C32.055 29.1171 30.7864 26.4955 28.367 25.0987ZM25.135 26.6967L24.2683 26.1964C25.3067 26.0703 26.3924 26.268 27.367 26.8307C29.251 27.9184 30.1612 30.0488 29.7844 32.0753C29.7069 32.4919 29.9005 32.9122 30.2675 33.1241L40.7846 39.1962C41.2629 39.4723 41.4268 40.0839 41.1506 40.5622C40.8745 41.0405 40.2629 41.2043 39.7846 40.9282L29.2675 34.8562C28.9005 34.6442 28.4397 34.6867 28.1176 34.9622C26.5511 36.3017 24.251 36.5787 22.367 35.491C21.3924 34.9282 20.6783 34.0868 20.2683 33.1246L21.135 33.6249C23.0481 34.7295 25.4945 34.074 26.5991 32.1608C27.7036 30.2477 27.0481 27.8013 25.135 26.6967Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_73_1353">
      <rect width="48" height="48" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M9.99985 6.19994C9.99985 4.29994 8.79985 2.59994 6.99985 1.99994V5.69994H3.99985V1.99994C2.19985 2.59994 0.999847 4.29994 0.999847 6.19994C0.999847 8.09994 2.19985 9.79994 3.99985 10.3999V21.3999C3.99985 21.7999 4.19985 21.9999 4.49985 21.9999H6.49985C6.79985 21.9999 6.99985 21.7999 6.99985 21.4999V10.4999C8.79985 9.89994 9.99985 8.19994 9.99985 6.19994ZM15.9998 7.99994C15.8998 7.99994 15.9998 7.99994 15.9998 7.99994C12.0998 8.09994 8.99985 11.1999 8.99985 14.9999C8.99985 18.8999 12.0998 21.9999 15.9998 21.9999C19.8998 21.9999 22.9998 18.8999 22.9998 14.9999C22.9998 11.0999 19.8998 7.99994 15.9998 7.99994ZM15.9998 19.9999C13.1998 19.9999 10.9998 17.7999 10.9998 14.9999C10.9998 12.1999 13.1998 9.99994 15.9998 9.99994C18.7998 9.99994 20.9998 12.1999 20.9998 14.9999C20.9998 17.7999 18.7998 19.9999 15.9998 19.9999ZM14.9998 10.9999V15.9999L18.5998 18.1999L19.3998 16.9999L16.4998 15.2999V10.9999H14.9998Z" fill={fill} className="svgFill"/>
    // </svg>
  )
}

PprIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PprIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PprIcon)
