import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment'


import { GET_ACTION_LOG_TABLE_URL } from '@/constants/apiRoutes'
import { GET_ACTION_LOG_TABLE , ACTION_LOG_TABLE } from '@/store/actions/actionLog'
import { successActionLogTable, errorActionLogTable } from '@/store/actions/actionLog/getTable'
import { getTableParameters, getSelectedElement, getActivitiLogFilterValue, getDatesRange } from '@/store/selectors/actionLog'
import getTimeZoneOffset from '@/helpers/getTimeZoneOffset';



function* getActionLogTableSaga({payload}) {
  try {
    const tableParameters = yield select(getTableParameters)
    const {userId, tenantId, groupId} = yield select(getSelectedElement)
    const filterValue = yield select(getActivitiLogFilterValue)
    const { start, end } = yield select(getDatesRange)
    const response = yield request({
      url: GET_ACTION_LOG_TABLE_URL({
        params: {          
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          searchText: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          actions: filterValue['action']?.map((item) => item.value),
          appNames: filterValue['appName']?.map((item) => item.value),
          objectTypes: filterValue['objectType']?.map((item) => item.value),
          userIds: filterValue['fullName'].length ? filterValue['fullName']?.map((item) => item.value) : [userId],
          groupId,
          tenantId,
          fromDate: start && moment(start).format('DD.MM.YYYY HH:mm'),
          toDate: end && moment(end).format('DD.MM.YYYY HH:mm'),
          timeZoneOffset: getTimeZoneOffset(),
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data
        yield put(successActionLogTable({
        data: content,
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
    } else {
      yield put(successActionLogTable())
    }

  } catch (error) {
    yield put(errorActionLogTable({}))
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_ACTION_LOG_TABLE.REQUEST, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.PAGE, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.SORT, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.PER_PAGE, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.REQUEST_QUERY, getActionLogTableSaga)
}
