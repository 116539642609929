import {
  put, takeLatest, select, take, delay
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import installationFormatFields from '@/helpers/installationFormatFields'
import get from 'lodash/get'
import set from 'lodash/set'
import noop from 'lodash/noop'
import cloneDeep from 'lodash/cloneDeep'
import {
  getCard,
} from '@/store/selectors/installation'
import { successEditObject } from '@/store/actions/installation/editObject'
import errorsMapper from '@/constants/errors/createObjectElement'
import { EDIT_OBJECT } from '@/store/actions/installation'
import { requestUploadInstallationObjectFile } from '@/store/actions/installation/uploadObjectFile'
import { requestDeleteInstallationObjectFile } from '@/store/actions/installation/deleteObjectFile'
import {
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import { INSTALLATIONS_OBJECT_ENDPOINT, manualObjectVerification } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { CONNECTED_LINES_OBJECT_TYPES } from '@/constants/instalationPassport/types'
import { requestGetConnectedLinesCoordinates } from '@/store/actions/installation/getConnectedLinesCoordinates'

function* editObjectSaga({ payload }) {
  const {
    formValues,
    setErrors,
    values,
    setUrlFormValues = noop,
    setAlertConfig,
    type,
  } = payload
  try {
    yield put(setGlobalLoading(true))
    setAlertConfig({})
    const cardCopy = yield select(getCard)
    const cardPhotoId = get(cardCopy, 'data.photoId', null)
    const cardFileId = get(cardCopy, 'data.fileId', null)
    const asuId = get(cardCopy, 'data.asuId', null)
    const parentId = get(cardCopy, 'data.parentId', null)
    const parentName = get(cardCopy, 'data.parentName', null)
    const objectState = get(cardCopy, 'data.objectState', null)
    const photoNameFromCard = (cardCopy.data.objectFields.filter((element) => element.fieldName === 'PHOTO_TITLE')[0] || {}).value || null
    const valuesDeepClone = cloneDeep(values)
    // set(valuesDeepClone, 'GENERAL_INFORMATION.EQUIPMENT_TYPE', 'DEFAULT_EQUIPMENT_TYPE')
    const objectElementName = get(valuesDeepClone, 'GENERAL_INFORMATION.NAME', null)
    const objectId = get(valuesDeepClone, 'GENERAL_INFORMATION.IDENTIFIER', null)
    const latitude = get(valuesDeepClone, 'LOCATION.LATITUDE', null)
    const longitude = get(valuesDeepClone, 'LOCATION.LONGITUDE', null)
    const integrationType = get(valuesDeepClone, 'integrationType', null)
    const prevObjectElementFieldsDto = get(valuesDeepClone, 'prevObjectElementFieldsDto', {})
    const objectElementId = get(valuesDeepClone, 'objectElementId', null)
    const photoFile = get(values, 'ATTACHMENT.PHOTO_TITLE', null)
    const PDFFile = get(values, 'ATTACHMENT.DOCUMENT_TITLE', null)
    const matchObject = get(valuesDeepClone, 'GENERAL_INFORMATION.MATCH_OBJECT', null)
    delete valuesDeepClone.LOCATION.LATITUDE
    delete valuesDeepClone.LOCATION.LONGITUDE
    delete valuesDeepClone.GENERAL_INFORMATION.NAME
    delete valuesDeepClone.integrationType
    delete valuesDeepClone.prevObjectElementFieldsDto
    delete valuesDeepClone.objectFields
    // delete valuesDeepClone.GENERAL_INFORMATION.EQUIPMENT_TYPE
    delete valuesDeepClone.ATTACHMENT
    delete cardCopy.elementsInfo
    delete valuesDeepClone.path
    delete valuesDeepClone.elementsInfo
    delete valuesDeepClone.GENERAL_INFORMATION.MATCH_OBJECT
    delete valuesDeepClone.options
    if (matchObject) {
      yield request({
        url: manualObjectVerification({
          params: {
            objectId: matchObject,
            verifyObjectId: objectElementId,
          }
        }),
        method: 'post',
      })
    }
    
    if ((!photoFile && cardPhotoId) || (photoFile?.name !== photoNameFromCard && cardPhotoId)) {
      yield put(requestDeleteInstallationObjectFile({
        id: formValues.id,
        fileId: cardPhotoId,
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_DELETE_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }
    if (!PDFFile && cardFileId) {
      yield put(requestDeleteInstallationObjectFile({
        id: formValues.id,
        fileId: cardFileId,
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_DELETE_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }
    if (photoFile && (photoFile?.name !== photoNameFromCard || !cardPhotoId)) {
      yield put(requestUploadInstallationObjectFile({
        elementId: formValues.id,
        file: photoFile,
        title: photoFile.name,
        fileType: 'PHOTO',
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_UPLOAD_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }
    if (PDFFile && !cardFileId) {
      yield put(requestUploadInstallationObjectFile({
        elementId: formValues.id,
        file: PDFFile,
        title: PDFFile.name,
        fileType: 'DOCUMENT',
      }))
      const action = yield take('*')
      if (action.type === '@/INSTALLATION_PAGE_UPLOAD_INSTALLATION_OBJECT_FILE_ERROR') {
        return null
      }
    }

    const fields = Object.keys(valuesDeepClone).reduce((accumulator, rootName) => {
      if (typeof valuesDeepClone[rootName] !== 'object') {
        return accumulator
      }
      const childField = Object.keys(valuesDeepClone[rootName] || {}).reduce((buffer, childName) => {
        if (childName === 'customFields') {
          const customFields = get(valuesDeepClone, `${rootName}.customFields`, []).reduce((customFieldsAccumulator, element) => {
            if (element.fieldName && element.value && !element.remove) {
              return [
                ...customFieldsAccumulator,
                {
                  ...element,
                  fieldName: element.fieldName,
                  value: element.value,
                  passportBlock: rootName,
                },
              ]
            }
            return customFieldsAccumulator
          }, [])
          return [
            ...buffer,
            ...customFields,
          ]
        }
        return [
          ...buffer,
          {
            ...prevObjectElementFieldsDto[`${rootName}.${childName}`] || {},
            fieldName: `${rootName}.${childName}`,
            value: get(valuesDeepClone, `${rootName}.${childName}`, null),
            passportBlock: rootName,
          },
        ]
      }, [])
      return [
        ...accumulator,
        ...childField,
      ]
    }, [])

    const formattedFields = installationFormatFields(fields)
    // const formattedFieldsWithFilterOutEmptyFields = formattedFields.filter((el) => el.value)
    const body = {
      name: objectElementName,
      objectIdOuter: objectId,
      point: {
        latitude,
        longitude,
      },
      objectFields: formattedFields,
      integrationType,
      installationType: type,
      objectState,
      id: formValues.id,
      asuId,
      parentId,
      parentName
    }
    yield delay(300)
    const { data } = yield request({
      url: INSTALLATIONS_OBJECT_ENDPOINT,
      method: 'post',
      body,
    })
    if (data.id) {
      const {
        rootId, path, parentGeoZoneId, parentId: parentTreeId, FILTER,
      } = formValues
      const newPath = path.replace(parentTreeId, data.parentId)
      const toast = createNotifications()
      const tree = treeHandlers.trees['installations-tree']

      const nodeData = tree.handlers.getNodeData(data.id)
      tree.handlers.setNodeData(data.id, {
        ...nodeData,
        name: data.name,
        elementName: data.name,
      })
      setUrlFormValues({
        id: data.id,
        type: OBJECT_ELEMENT,
        rootId,
        parentGeoZoneId,
        parentTreeId: data.parentId,
        path: newPath,
        FILTER,
      })
      if (CONNECTED_LINES_OBJECT_TYPES.some(type => type === data.installationType)) {
        yield put(requestGetConnectedLinesCoordinates({
          id: data.parentId
        }))
      }
      yield put(setGlobalLoading(false))
      toast({
        title: 'Редактирование объекта',
        type: 'success',
        description: 'Изменения сохранены.',
      })
      yield put(successEditObject())
    }
  } catch (error) {
    yield put(setGlobalLoading(false))
    setAlertConfig({})
    const errors = get(error, 'response.data.details', []).reduce((accumulator, element) => {
      const errorMessageAndLocation = element.message.split(': ')
      return {
        ...accumulator,
        [errorMessageAndLocation[1]]: errorsMapper[errorMessageAndLocation[0]]
          || errorMessageAndLocation[0],
      }
    }, {})
    const errorObject = {}
    Object.keys(errors).map((errorLocation) => {
      set(errorObject, errorLocation, errors[errorLocation])
      return errorLocation
    })
    setErrors(errorObject)
    console.log('function*editObjectSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(EDIT_OBJECT.REQUEST, editObjectSaga)
}
