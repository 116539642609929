import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2151_5260)">
      <path d="M13 14H3V12L4 10L7 9V11L9 9L12 10L13 12V14Z" fill="#273244"/>
      <path d="M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z" fill={fill}/>
      </g>
      <defs>
      <clipPath id="clip0_2151_5260">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <g mask="url(#mask-users)">
    //     <path d="M37 38C37 35.0826 35.8411 32.2847 33.7782 30.2218C31.7153 28.1589 28.9174 27 26 27C23.0826 27 20.2847 28.1589 18.2218 30.2218C16.1589 32.2847 15 35.0826 15 38M31 19C31 21.7614 28.7614 24 26 24C23.2386 24 21 21.7614 21 19C21 16.2386 23.2386 14 26 14C28.7614 14 31 16.2386 31 19Z" stroke={stroke} className="svgStroke" strokeWidth="1.3" strokeLinecap="round" />
    //   </g>
    // </svg>
  )
}

UserIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserIcon)
