import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReverseQuestionIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_73_1829)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 5C7.44772 5 7 5.44772 7 6H6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6C10 6.43274 9.85964 6.85381 9.6 7.2L9 8C8.67544 8.43274 8.5 8.95907 8.5 9.5V10H7.5V9.5C7.5 8.7427 7.74562 8.00584 8.2 7.4L8.8 6.6C8.92982 6.4269 9 6.21637 9 6C9 5.44772 8.55228 5 8 5ZM8.5 12V11H7.5V12H8.5Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_73_1829">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fill={fill} className="svgFill" fillRule="evenodd" clipRule="evenodd" d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM10.6043 9.5342C9.75527 10.1172 9.63847 10.6515 9.63847 11.1418C9.63847 11.3407 9.5612 11.5315 9.42373 11.6723C9.2862 11.8129 9.09967 11.892 8.9052 11.892C8.71073 11.892 8.52427 11.8129 8.38673 11.6723C8.2492 11.5315 8.172 11.3407 8.172 11.1418C8.172 9.96767 8.69993 9.0342 9.78613 8.28773C10.7959 7.59433 11.3669 7.15493 11.3669 6.18879C11.3669 5.53181 11.0002 5.03293 10.2413 4.66371C10.0627 4.57691 9.6652 4.49224 9.176 4.49813C8.56213 4.50617 8.08553 4.65621 7.7184 4.95844C7.026 5.5286 6.96733 6.14913 6.96733 6.15824C6.96273 6.25676 6.93913 6.3534 6.898 6.4426C6.85687 6.5318 6.79896 6.6118 6.72759 6.67813C6.65621 6.7444 6.57278 6.79573 6.48204 6.829C6.3913 6.86233 6.29504 6.877 6.19875 6.87227C6.10245 6.86753 6.00802 6.8434 5.92083 6.80133C5.83365 6.75927 5.75542 6.7 5.69062 6.627C5.62581 6.55393 5.5757 6.4686 5.54314 6.37573C5.51058 6.28291 5.49621 6.18442 5.50085 6.0859C5.50661 5.95569 5.59513 4.78268 6.79818 3.7924C7.42193 3.27905 8.21547 3.01219 9.15507 3.00039C9.8202 2.99235 10.4451 3.10757 10.8687 3.31227C12.1367 3.92583 12.8333 4.94879 12.8333 6.18879C12.8333 8.00114 11.6497 8.81515 10.6051 9.53359L10.6043 9.5342ZM8.85287 15C8.64567 15 8.44313 14.9371 8.27087 14.8194C8.0986 14.7016 7.96433 14.5343 7.88507 14.3384C7.8058 14.1426 7.78507 13.9271 7.82547 13.7192C7.86587 13.5113 7.96567 13.3203 8.11213 13.1705C8.25867 13.0205 8.44527 12.9185 8.64847 12.8771C8.85167 12.8358 9.06227 12.857 9.25373 12.9381C9.44513 13.0193 9.60873 13.1566 9.7238 13.3329C9.83893 13.5091 9.90033 13.7163 9.90033 13.9283C9.90033 14.2125 9.79 14.4851 9.59353 14.6861C9.39707 14.8871 9.13067 15 8.85287 15Z"/>
    // </svg>
  )
}

ReverseQuestionIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReverseQuestionIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReverseQuestionIcon)
