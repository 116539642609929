import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ to }) => to === '#' && `
    opacity: 0.2;
    // pointer-events: none;
  `}
`

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  padding: 50px 10px 0 10px;
  max-height: ${({ theme }) =>
    `calc(100vh - ${(theme.sizes.headerHeight + theme.sizes.menuRowHeight)}px)`
};
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        &:not(:first-child) {
          margin-top: 10px;
        }
      `
    }
    return `
      &:first-child {
        margin-top: 10px;
      }
    `
  }}

  &:not(:first-child) {
    margin-top: 10px;
  }
`

export const Icon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 5px 10px;
`

export const TitleRow = styled.div`
  display: flex;
  ${({ showTitle }) => showTitle && `
    display: none;
  `}
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        width: 180px;
      `
    }
    return `
      width: 200px;
    `
  }}
`

export const Title = styled.h3`
  font-family: 'Inter', sans-serif;
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  ${({ isBigChildren }) => isBigChildren && `
    overflow: hidden;
  `}
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        font-size: 1.4rem;
        font-weight: 500;
      `
    }
    return `
      font-weight: ${theme.fontWeights.bold};
      font-size: 1.4rem;
      line-height: 2.4rem;
    `
  }}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FAFBFB;
  border-radius: 10px;
  padding: 5px 0 5px 0;
`

export const FoldButton = styled(Row)`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.sizes.menuRowHeight}px;
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
  z-index: 1;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Wrapper = styled.div`
  height: 48px;
  width: 100%;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`
export const TitlePage = styled.h1`
  font-size: 24px;
  color: #1061DF;
  padding-left: 10px;
`
export const Header = styled.div`
  width: 100vw;
  display: flex;
  height: 50px;
  padding: 0 5px 10px 0;
  align-items: flex-end
  justify-content: space-between;
  margin-bottom: 5px;
  padding-bottom: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #FAFBFB;
  z-index: 100;

  & > svg {
    cursor: pointer;
  }
`

