import { GET_CHECKED_OBJECTS_FILE } from '@/store/actions/objectsGroups/index';


export const requestGetCheckedObjectsFile = (payload) => ({
  type: GET_CHECKED_OBJECTS_FILE.REQUEST,
  payload,
})

export const successGetCheckedObjectsFile = (payload) => ({
  type: GET_CHECKED_OBJECTS_FILE.SUCCESS,
  payload,
})

export const errorGetCheckedObjectsFile = (payload) => ({
  type: GET_CHECKED_OBJECTS_FILE.ERROR,
  payload,
})