import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReverseOkIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_73_1921)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM5 7L4 8L7 11L12 6L11 5L7 9L5 7Z" fill={fill} className="svgFill"/>
      </g>
      <defs>
      <clipPath id="clip0_73_1921">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

    // <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fill={fill} className="svgFill" fillRule="evenodd" clipRule="evenodd" d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM14.6716 4.1142C14.3812 3.92865 13.9853 3.97337 13.7524 4.21805L8.44688 9.79164C8.18931 10.0622 7.73948 10.084 7.45235 9.83987L5.35566 8.05698C5.08953 7.83067 4.67872 7.83057 4.41246 8.05674L3.72311 8.64228C3.46543 8.86116 3.42589 9.22227 3.63096 9.48393L6.96252 13.7348C7.09305 13.9013 7.30278 14 7.52614 14H8.42108C8.64778 14 8.86021 13.8983 8.99017 13.7277L15.3745 5.34279C15.5949 5.05336 15.5175 4.65448 15.2018 4.45286L14.6716 4.1142Z"/>
    // </svg>
  )
}

ReverseOkIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReverseOkIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReverseOkIcon)
