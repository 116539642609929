import styled from 'styled-components'
import Button from '@/components/blocks/Button'

export const SignInStyleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url(${({ theme }) => theme.images.signIn}) no-repeat center center;
  background-size: cover;

  ${({ hidden }) => hidden && `
    background: none;
  `}
`

export const LogoContainer = styled.div`
  margin-bottom: 30px;
  width: 48px;
  height: 48px;
   > svg {
    width: 100%;
    height: 100%;
  }
`

export const TitleLogo = styled.div`
  font-size: 14px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  min-height: 375px;
  height: 375px;
  padding: 45px 85px;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  box-shadow: ${({ theme }) => theme.defaultBoxShadow(theme.colors.shadows.widget)};
  border-radius: 4px;

  > svg {
    min-width: 104px;
    min-height: 43px;
    max-height: 43px;
    margin-bottom: 22.5px;
  }

  ${({ hidden }) => hidden && `
    display: none;
  `}

  @media (max-width: 910px) {
    width: 300px;
    padding: 35px 65px;
  }

`

export const Text = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.colors.online};
  ${({ error, theme }) => error && `
    color: ${theme.colors.backgrounds.notification.error}
  `};
`

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.colors.subTitle};
  text-decoration-line: underline;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
  margin-top: 10px;
`
