import {
  MINE_SHAFT,
  YELLOW_ALARM_WARNING_CONTRAST
} from '@/constants/styles/defaultTheme'

const defaultData = [
  {time: '00:00', percent: null},
  {time: '02:00', percent: null},
  {time: '04:00', percent: null},
  {time: '06:00', percent: null},
  {time: '08:00', percent: null},
  {time: '10:00', percent: null},
  {time: '12:00', percent: null},
  {time: '14:00', percent: null},
  {time: '16:00', percent: null},
  {time: '18:00', percent: null},
  {time: '20:00', percent: null},
  {time: '22:00', percent: null},
  {time: '24:00', percent: null},
]

export const formatDataForGraph = (data, intl, isSecond = false) => {
  if (!data || !data.length) {
    return
  }

  let acc = []
  const isNumber = (element) => element.percent !== null

  const unitedData = defaultData.map(defaultItem => {
    const dataItem = data.find(item => item.time === defaultItem.time)
    return dataItem ? { time: defaultItem.time, percent: dataItem.percent } : defaultItem
  }).concat(data.filter(({time}) => !defaultData.find(item => item.time === time)))
    .sort((a, b) => b.time.localeCompare(a.time))

  const getFirsDayData = (array) => {
    const localArray = [...array]
    const percentValueIndex = array.findIndex(isNumber)
    if (array.length === 0) {
      return
    }
    if (array.length > 0 && percentValueIndex < 0) {
      acc = [...acc, ...array]
    }
    for (let i = 0; i <= percentValueIndex; i++) {
      acc.push({ time: array[i].time, percent: array[percentValueIndex].percent})
    }
    localArray.splice(0, percentValueIndex + 1)
    if (localArray.length !== array.length) {
      getFirsDayData(localArray)
    }

    return acc
  }

  const getSecondDayData = (data) => {
    const firstElementPercent = data[0].percent
    const lastElementPercent = data[data.length - 1].percent
    if (firstElementPercent === lastElementPercent) {
      return data
    }

    return data.map((item, index) => {
      if (item.percent === firstElementPercent && item.id === index) {
        return {...item, percent: lastElementPercent}
      }

      return item
    })
  }

  const firstDayResult = getFirsDayData(unitedData).sort((a, b) => a.time.localeCompare(b.time)).map((item, index) => { return { id: index, ...item } })
  const secondDayResult = getSecondDayData(firstDayResult)

  return isSecond
    ? secondDayResult.map((element) => ({
       id: element.id,
       label: element.time,
       y: element.percent,
       title: element.percent ? intl.messages['scheduleManager.form.on'] : intl.messages['scheduleManager.form.off']
    }))
    : firstDayResult.map((element) => ({
       id: element.id,
       label: element.time,
       y: element.percent,
       title: element.percent ? intl.messages['scheduleManager.form.on'] : intl.messages['scheduleManager.form.off']
    }))
}

export const getScheduleChartOptions = (isLight, chartData, intl, isSecond) => {
  return {
    height: 500,
    animationEnabled: true,
    exportEnabled: false,
    backgroundColor: 'transparent',
    axisY: {
      labelFontFamily: 'Inter, sans-serif',
      labelFontSize: 10,
      labelFontColor: MINE_SHAFT,
      labelFormatter: ( e ) => {
        return isLight ? e.value + '%' : e.value ? intl.messages['scheduleManager.form.on'] : intl.messages['scheduleManager.form.off']
      },
      minimum: 0,
      maximum: 100,
      interval: isLight ? 10 : 100,
      gridDashType: 'dash',
      includeZero: true,
      gridColor: MINE_SHAFT
    },
    axisX:{
      labelFontFamily: 'Inter, sans-serif',
      labelFontSize: 10,
      labelFontColor: MINE_SHAFT,
      includeZero: true,
      margin: 0,
      interval: 1,
    },
    toolTip: {
      fontFamily: 'Inter, sans-serif',
      content: isLight
        ? `${intl.messages['scheduleManager.time']}: {label} <br/> ${intl.messages['scheduleManager.level']}: {y}%`
        : `${intl.messages['scheduleManager.time']}: {label} <br/> ${intl.messages['scheduleManager.state']}: {title}`
    },
    legend: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 14,
      fontWeight: 500,
      maxHeight: 30,
    },
    data: [
      {
        type: 'stepArea',
        color: YELLOW_ALARM_WARNING_CONTRAST,
        connectNullData: true,
        showInLegend: true,
        lineThickness: 3,
        markerSize: 1,
        legendText: isLight ? intl.messages['scheduleManager.dimmingLevel'] : intl.messages['scheduleManager.shunoWork'],
        dataPoints: formatDataForGraph(chartData, intl, isSecond)
      }
    ]
  }
}