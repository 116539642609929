import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CheckBoxWrapper = styled.div`
  width: 38px;
  height: 100%;
  min-width: 38px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
`
export const ImageContainer = styled.div`
  background-image: url(${({ url }) => url});
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-left: 15px;
  margin-right: 10px;
  position: relative;
  border: 2px solid ${({ theme, status }) => {
    if (!status) {
      return theme.colors.colors.disabled
    }
    return theme.colors.messages[status]
  }};
  overflow: hidden;
  &:after{  
    position: absolute;
    content: '';
    top: 15px;
    left: 15px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${({ theme, status }) => {
    if (!status) {
      return theme.colors.colors.disabled
    }
    return theme.colors.messages[status]
  }};
  }
  background-size: cover;
  background: position: center;
  background-repeat: no-repeat;
`
export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
`
