import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ObjectsGroups from '@/containers/pages/ObjectsGroups/component'
import {
  getGlobalLoading,
  getGlobalFilters,
  getUserApplications,
  getUserData
} from '@/store/selectors/appSettings'
import {
  getCheckedObjectsFileStatus,
  getCreateObjectsGroupsMapObjects,
  getCreateObjectsGroupsMapObjectsStatus,
  getCreateTreeData,
  getCreateTreeStatus,
  getGroupStructureFileStatus,
  getObjectsGroup,
  getObjectsGroupsMapObjects,
  getObjectsGroupsMapObjectsStatus,
  getObjectsGroupsSelectedNode,
  getSearchTree,
  getTreeData,
  getTreeStatus
} from '@/store/selectors/objectsGroups'
import { onToggleTree } from '@/store/actions/appSettings'
import { requestGetObjectsGroupsTree } from '@/store/actions/objectsGroups/getTree'
import { setObjectsGroupsTreeNode } from '@/store/actions/objectsGroups'
import { requestGetObjectsGroup } from '@/store/actions/objectsGroups/getObjectsGroup'
import { requestCreateObjectsGroup } from '@/store/actions/objectsGroups/createObjectsGroup'
import { requestEditObjectsGroup } from '@/store/actions/objectsGroups/editObjectsGroup'
import { requestDeleteObjectsGroup } from '@/store/actions/objectsGroups/deleteObjectsGroup'
import { requestGetObjectsGroupsFilterOptions } from '@/store/actions/dictionaries/objectsGroupsFilterOptions'
import {
  getObjectsGroupsFilterOptionsData,
  getObjectsGroupsFilterOptionsLoading
} from '@/store/selectors/dictionaries/objectsGroupsFilterOptions'
import { requestGetCreateObjectsGroupTree } from '@/store/actions/objectsGroups/getCreateObjectsGroupTree'
import { requestGetCreateObjectsGroupMapObjects } from '@/store/actions/objectsGroups/getCreateObjectsGroupMapObjects'
import { requestGetCheckedObjectsFile } from '@/store/actions/objectsGroups/getCheckedObjectsFile';
import { requestGetGroupObjectsStructureFile } from '@/store/actions/objectsGroups/getGroupStructureFile';

const mapStateToProps = (state) => ({
  globalLoading: getGlobalLoading(state),
  globalFilters: getGlobalFilters(state),
  treeData: getTreeData(state),
  treeStatus: getTreeStatus(state),
  createTreeData: getCreateTreeData(state),
  createTreeStatus: getCreateTreeStatus(state),
  card: getObjectsGroup(state),
  selectedObjectsGroup: getObjectsGroupsSelectedNode(state),
  objectsGroupsFilterOptions: getObjectsGroupsFilterOptionsData(state),
  objectsGroupsFilterOptionsLoading: getObjectsGroupsFilterOptionsLoading(state),
  displayElements: getObjectsGroupsMapObjects(state),
  displayElementsStatus: getObjectsGroupsMapObjectsStatus(state),
  createDisplayElements: getCreateObjectsGroupsMapObjects(state),
  createDisplayElementsStatus: getCreateObjectsGroupsMapObjectsStatus(state),
  userApplications: getUserApplications(state),
  userData: getUserData(state),
  searchTreeData: getSearchTree(state),
  fileExportCheckedObjectsStatus: getCheckedObjectsFileStatus(state),
  fileExportGroupStructureStatus: getGroupStructureFileStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetTree: (payload) => dispatch(requestGetObjectsGroupsTree(payload)),
  requestGetCreateTree: (payload) => dispatch(requestGetCreateObjectsGroupTree(payload)),
  setNode: (payload) => dispatch(setObjectsGroupsTreeNode(payload)),

  requestGetObjectsGroup: (payload) => dispatch(requestGetObjectsGroup(payload)),
  requestCreateObjectsGroup: (payload) => dispatch(requestCreateObjectsGroup(payload)),
  requestEditObjectsGroup: (payload) => dispatch(requestEditObjectsGroup(payload)),
  requestDeleteObjectsGroup: (payload) => dispatch(requestDeleteObjectsGroup(payload)),

  requestGetObjectsGroupsFilterOptions: (payload) => dispatch(requestGetObjectsGroupsFilterOptions(payload)),
  requestGetCreateMapObjects: (payload) => dispatch(requestGetCreateObjectsGroupMapObjects(payload)),
  onToggleTree: (payload) => dispatch(onToggleTree(payload)),
  getCheckedObjectsFile: (payload) => dispatch(requestGetCheckedObjectsFile(payload)),
  getGroupStructureFile: (payload) => dispatch(requestGetGroupObjectsStructureFile(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ObjectsGroups))