import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 10px 0;
`
export const ItemContainer = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.borders.default};
`

export const ItemTitle = styled.div`
  width: 100%;
  font-family: Inter;
  white-space: pre-wrap;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  padding: 5px 0;
  padding-left: 45px;
  color: ${({ theme }) => theme.colors.backgrounds.button};
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 7px;
  height: 100%;
  width: 20%;
  .svgFill{
    height: 18px;
    width: 18px;
    ${({ isCross }) => (isCross
    ? `
      transform: rotate(45deg);
      transform-origin: center;
    `
    : null)}
  }
  cursor: pointer;
`

export const ContentContainer = styled.div`
  width: 100%;
  padding: 15px;
`

export const ContainerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const SecondaryText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.disabled};
  text-align: left;
  white-space: pre-wrap;
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
`

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  button {
    width: 100px;
    height: 38px;
    opacity: 400;
    z-index: 1;
    opacity: 1;
  }
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: ${({ theme }) => theme.colors.backgrounds.default}
  }
`
