import styled from 'styled-components'

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  position: relative;
`

export const Title = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
`

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  position: absolute;
  right: 0;
  width: 340px;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  z-index: 10;
`

export const Header = styled.div`
  padding: 15px;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`

export const IconContainer = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }
`

export const TypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
`

export const ObjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
`

export const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`

export const ObjectContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  padding: 5px 0 5px 10px;
  &:hover {
    background: #DDDDDD;
    border-color: transparent;
  }
`

export const TypeTitle = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  &:hover {
    color: #3E3C3E99;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ToggleContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px;
  &:hover {
    background: #DDDDDD;
  }
  svg {
    transform-origin: center;
    transform: rotate(180deg);
  }
  ${({ isOpen }) => isOpen && `
    svg {
      transform-origin: center;
      transform: rotate(0deg);
    }
  `}
`

export const ChildrenCount = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  background: #838D99;
  border-radius: 4px;
  padding: 5px;
  color: #FAFBFB;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
`