import styled from 'styled-components'
import { CheckBoxStyled } from '@/components/controls/CheckBox/styles'

export const TreeNode = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  padding-right: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .loader {
    width: 24px;
    height: 24px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
    ${({isLast}) => isLast &&`
    margin-bottom: 40px;
    `}
`
export const TreeNodeIcon = styled.div`
  margin: 0 5px;
`

export const TreeNodeToolTip = styled.div`
  cursor: pointer;
`

export const TreeNodeIconAndInfo = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${CheckBoxStyled} {
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
  }
`

export const TreeNodeInfo = styled.div`
  display: flex;
  margin-left: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const TreeNodeObjects = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  text-align: left;
  color: ${({ dark, theme }) => (dark
    ? theme.colors.colors.default
    : theme.colors.colors.disabled)};
`

export const TreeNodeArrow = styled.div`
  margin-right: 0;
  ${({ transform }) => (transform
    ? 'transform: rotate(180deg);'
    : 'transform: rotate(90deg);')}
`

export const TreeNodeTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 154px;;
  // max-width: ${({ width, type, isSearchMode }) => type ? `${width - (isSearchMode ? 100 : 64)}px` : '100%'}; 
  ${({ groupsTree, width, type }) => groupsTree &&`
    max-width: ${type ? `${width - 98}px` : '100%'}; 
  `}
`

export const ConteinerButtonGetMore = styled.div`
    top: 30px;
    width: 100%;
    height: 30px;
    position: absolute;
    border-radius: 5px;
    display: flex;
    align-items: center;
    align-content: space-around
    justify-content: center;

`

export const ButtonGetMore = styled.div`
    color: #007aff;
    align-items: center
    font-size: 14px;
    cursor: pointer;
`
