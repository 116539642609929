import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px 0 20px;
`

export const Title = styled.h3`
  font-family: 'Inter', sans-serif;
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  text-align: left;
  flex-grow: 1;
  ${({ isBigChildren }) => isBigChildren && `
    overflow: hidden;
  `}
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        font-size: 1.4rem;
        font-weight: 500;
      `
    }
    return `
      font-weight: ${theme.fontWeights.bold};
      font-size: 1.4rem;
      line-height: 2.4rem;
    `
  }}
`

export const LabelBlock = styled.div`
font-family: 'Inter', sans-serif;
display: flex;
cursor: pointer;
height: 100%;
opacity: 0.5;
align-items: center;
align-self: center;
justify-content: flex-start;
white-space: nowrap;
text-overflow: ellipsis;
text-align: left;
Line height: 24px;
${({ isBigChildren }) => isBigChildren && `
  overflow: hidden;
`}
${({ theme }) => {
  if (theme.themeName === LIGHT_THEME_NAME) {
    return `
      font-size: 1.6rem;
      font-weight: 500;
    `
  }
  return `
    font-weight: ${theme.fontWeights.bold};
    font-size: 1.4rem;
    line-height: 2.4rem;
  `
}}
`

export const WrapperTitile = styled.div`
`
export const Header = styled.div`
  width: 100vw;
  display: flex;
  height: 40px;
  align-items: center;
  gap: 50px;
  padding-left: 10px;
  background-color: #FAFBFB;
  z-index: 100;
  position: absolute;

  & > svg {
    cursor: pointer;
  }
`

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 100%;
  max-height: ${({ theme }) =>
    `calc(100vh - ${(theme.sizes.headerHeight + theme.sizes.menuRowHeight)}px)`
};
  overflow-y: auto;
  overflow-x: hidden;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: #FAFBFB;
  border-radius: 10px;
  padding: 20px;
`

export const ControlBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 10px;
`

export const InfoBlock = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  text-align: left;
  white-space: pre-wrap;
`

export const FooterInfoBlock = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  text-align: center;
  height: 100px;
  margin-top: auto;
`

export const WrapperWidgetBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const IconWrapper = styled.div`
  min-width: 28px;
  mit-height: 28px;
`





