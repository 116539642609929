import styled from 'styled-components'

export const BaseLayoutStyled = styled.div`
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const LayoutContentWrapper = styled.div`
  position: relative;
  display: flex;
  height: calc(100vh - ${({ theme }) => theme.sizes.headerHeight}px);
  background: #F6F9FB;
  box-sizing: border-box;

  @media (max-width: 910px) {
    height: 100vh;
   }
`

export const ContentWithoutSidebars = styled.div`
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.sizes.headerHeight}px);
  overflow: auto;
  flex-grow: 1;
  @media (max-width: 910px) {
    height: calc(100vh - 76px);
   }
`
export const ErrorLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const StyledText = styled.h2`
  margin-bottom: 10px;
`
